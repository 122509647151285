import { useTheme } from "@emotion/react";
import { Box, ButtonBase, InputBase, ToggleButton, ToggleButtonGroup } from "@mui/material";
import cx from 'classnames';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MConfirmationWithValue from "../../../../components/global/Modals/MConfirmationWithValue";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ShLabel from "../../../../components/theme/label/ShLabel";
import Heading from "../../../../components/theme/text/Heading";
import Subheading from "../../../../components/theme/text/Subheading";
import Text from "../../../../components/theme/text/Text";
import { useGetCarrierBarcodeMutationMutation, useGetCarriersSelectQuery } from "../../../../newapi/carrier/carrierSlice";
import { useFinalizeOrderMutation, useFindLocationInPickingJobMutation, useFindPickingJobMutation, useGetDeliveryOptionsQuery, useSetJokerMutation } from "../../../../newapi/global/echeckSlice";
import { useGetShippersSelectWithProfilesQuery } from "../../../../newapi/shipper/shipperSlice";
import { tokens } from "../../../../theme";
import { showToastMessage } from "../../../../utils/toasts";
import ShippedOrders from "./components/ShippedOrders";
import EcheckView from "./views/EcheckView";
import PreviousOrderEchecked from "./views/PreviousOrderEchecked";
import SelectedOrderView from "./views/SelectedOrderView";

const LocationsEcheckView = (props) => {
    const { t, ready,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 

    const [showOptions, setShowOptions] = useState(false);
    const inputRef = useRef(null);
    const inputRefWhenOneOrderWasAlreadyScanned = useRef(null);

    const [currentOrder, setCurrentOrder] = useState(null);
    const [currentOrderWarehouseId, setCurrentOrderWarehouseId] = useState(null);
    const [toScanRows, setToScanRows] = useState(null);

    const [toScanLocations, setToScanLocations] = useState(null);

    // getpicking
    const [findLocationInPickingJob, {data: orderInJobData, isLoading: orderInJobLoading, isFetching: orderInJobFetching}] = useFindLocationInPickingJobMutation();

    useEffect(() => {
        if(props.incData){
            setToScanLocations(props.incData.locations);
            // Match firstScannedBarcode in .lcoations (probably)
        }
    }, [props.incData])

    const [suggestedCarrier, setSuggestedCarrier] = useState(null);

    const [selectedShipper, setSelectedShipper] = useState('');
    const [selectedShipperProfile, setSelectedShipperProfile] = useState('');

    const [totalWeight, setTotalWeight] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const [matchedProduct, setMatchedProduct] = useState(null);
    const [incCarrier, setIncCarrier] = useState("dummy");

    const [finalizeResponse, {isLoading: finalizeLoading,isFetching:finalizeFetching}] = useFinalizeOrderMutation();

    const [setJokerResponse, {isLoading: setJokerLoading}] = useSetJokerMutation();
    const [deliveryOptions, setDeliveryOptions] = useState({});
    const [carrierForDeliveryToFetch, setCarrierForDeliveryToFetch] = useState(null);
    const [deliveryOptionsFetchData, setDeliveryOptionsFetchData] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const [previousOrder, setPreviousOrder] = useState(null);

    const { data: deliveryOptionsData } = useGetDeliveryOptionsQuery(deliveryOptionsFetchData, {
        skip: !deliveryOptionsFetchData
    });

    // const [carrierRows, setCarrierRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [closeOpen, setCloseOpen] = useState(false);
    const [paModalOpen, paModalSetOpen] = useState(false);

    const [modalData, setModalData] = useState(0);
    const [closeModalData, setCloseModalData] = useState(0);
    const [paModalData, setPaModalData] = useState(0);

    const [ fetchCarrierBarcode, {data: fetchedIncCarrier,isError, isLoading: isLoadingC, refetch: refetchCarrier}] = useGetCarrierBarcodeMutationMutation();

    const [carriers, setCarriers] = useState([{
        "carrierId" : null,
        "carrierName" : "",
        "totalProducts" : 0,
        "totalWeight" : 0,
        "deliveryOption": null,
        "rows": [],
    }]);

    const confirmModalOrder = (id) => {
        // setCurrentOrderId(id);
    }

    const confirmModal = {
        open: open,
        setOpen: setOpen,
        data:modalData,
        confirm: confirmModalOrder
    }

    const closeConfirmModalOrder = () => {
        props.setShowEcheck(false);
    }

    const confirmCloseModal = {
        open: closeOpen,
        setOpen: setCloseOpen,
        data:closeModalData,
        confirm: closeConfirmModalOrder
    }

    const confirmProductAmount = (amount) => {
        if (amount === 0) {
            return;
        }
        
        updateCarrierAndScanRows(parseInt(amount));
    }

    const productAmountModal = {
        open: paModalOpen,
        setOpen: paModalSetOpen,
        data:paModalData,
        confirm: confirmProductAmount
    }

    const [getMyJob, {data: individualJob, isLoading: invididualJobLoading}] = useFindPickingJobMutation();

    const { data: shippers } = useGetShippersSelectWithProfilesQuery({warehouseId: currentOrderWarehouseId},
        {
            skip: !currentOrderWarehouseId
        }
    );

    const [goFinalize, setGoFinalize] = useState(false);
    const [timer, setTimer] = useState(null);
    const [finalizeReady, setFinalizeReady] = useState(false);
    const [showSlide, setShowSlide] = useState(false); // Controls slide visibility

    useEffect(() => {
        if(!shippers || !currentOrder) return;
        setSelectedShipper(shippers.filter(shipper => shipper.id === currentOrder?.shipper?.id)[0])
        const shipperProfileId = currentOrder?.shipperProfile?.id;
        const selectedShipperProfile = shippers.flatMap(shipper => shipper.shipperProfiles).find(profile => profile.id === shipperProfileId);
        if(selectedShipperProfile){
            setSelectedShipperProfile(selectedShipperProfile);
        } 
    }, [shippers,currentOrder])

    

    useEffect(() => {
        if(inputRef.current && currentOrder !== null){
            console.log("called");
            inputRef.current.focus();
        }
    },[selectedShipperProfile,inputRef,currentOrder])

    useEffect(() => {   
        if(previousOrder !== null && inputRefWhenOneOrderWasAlreadyScanned.current) {
            inputRefWhenOneOrderWasAlreadyScanned.current.focus();
        }

    }, [previousOrder,inputRefWhenOneOrderWasAlreadyScanned])

    // Handle mouse movement to cancel the timer
    useEffect(() => {
        const handleMouseMove = () => {
            if (timer) {
                clearTimeout(timer); // Cancel the timer if the mouse moves
                setShowSlide(false); // Hide the slide
                setFinalizeReady(false); // Reset finalize state
            }
        };
        window.addEventListener('mousemove', handleMouseMove);

        // Cleanup the event listener and timer on component unmount or effect cleanup
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);  // Re-run this effect if `timer` changes

    // Timer logic
    useEffect(() => {
        if (goFinalize) {
            //Temp removal of 5sec rule
            setFinalizeReady(true);
            return;

            // Start the timer for 5 seconds (or any duration you need)
            const newTimer = setTimeout(() => {
                setFinalizeReady(true); // Timer completed, ready to finalize
                setShowSlide(false); // Hide the slide once timer completes
            }, 5000); // 5000ms = 5 seconds

            setTimer(newTimer); // Store the timer ID so we can clear it later
            setShowSlide(true); // Show the slide while the timer is running

            // Cleanup the timer if finalized or component unmounts
            return () => {
                clearTimeout(newTimer);
            };
        }
    }, [goFinalize]);  // This effect runs when `goFinalize` state changes

    // Finalize logic (will only run if the timer completes and mouse doesn't move)
    useEffect(() => {
        if (finalizeReady) {
            if (selectedShipper.type === "bol") {
                if (carriers.every(carrier => carrier.deliveryOption !== null)) {
                    finalize();
                }
            } else {
                finalize();
            }

            setGoFinalize(false); 
        }
    }, [finalizeReady]);  // Runs when `finalizeReady` changes

    useEffect(() => {
        // console.log("delivery options data", deliveryOptionsData);
        if (deliveryOptionsData) {
            setDeliveryOptions(prev => ({
                ...prev,
                [carrierForDeliveryToFetch]: deliveryOptionsData.deliveryOptions
            }));
            setCarriers(prev => {
                const updatedCarriers = [...prev];
                updatedCarriers[carrierForDeliveryToFetch].deliveryOption = deliveryOptionsData.deliveryOptions?.find(option => option.recommended) || deliveryOptionsData.deliveryOptions[0];
                return updatedCarriers;
            });
            setCarrierForDeliveryToFetch(null); // Reset carrierToFetch after fetching
        }
    }, [deliveryOptionsData]);


    const selectShipper = (selected) => {
        var shipper = shippers?.filter(shipper => shipper.type !== 'bol' || currentOrder?.webshopcredentials?.webshop_type === 'bol')[selected];
        setSelectedShipper(shipper);
        setSelectedShipperProfile(-1);
    }

    const selectShipperProfile = (selected) => {
        var shipperProfile = selectedShipper?.shipperProfiles[selected];
        setSelectedShipperProfile(shipperProfile);
    }

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            if(currentOrder === null) {
                setPreviousOrder(null);

                const foundLocation = props.incData.locations.find(location => location.barcode === e.target.value);
                if(foundLocation){
                    // if(foundProduct.amountEchecked === foundProduct.amount){
                    // // if(currentOrder.amountEchecked === currentOrder.amount) {
                    //     props.setHighlightColor("orange");
                    //     e.target.value = "";
                    //     return;
                    // }

                    await findLocationInPickingJob({
                        pickingJobId:props.incData.pickingJobId,
                        barcode:e.target.value,
                        workspaceId:props.selectedWorkSpace.id,
                    })
                    .unwrap()
                    .then(res => {
                        if(res.order){
                            setOrderToScanData(res,e.target.value);
                        } else {
                            props.setHighlightColor("red");
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        props.setHighlightColor("red")
                    })
                } else {
                    props.setHighlightColor("red")
                }
            } else  {
                findLocalProduct(e.target.value);
            }
            e.target.value = "";
        }
    }

    const setOrderToScanData = (res, value) => {
        setCurrentOrder(res.order);
        setSuggestedCarrier(res.order?.suggestedCarrier);
        setToScanRows(res.order.orderlines);
        setCurrentOrderWarehouseId(res.order.warehouseId);

        //reset carriers
        setCarriers([{
            "carrierId" : null,
            "carrierName" : "",
            "totalProducts" : 0,
            "totalWeight" : 0,
            "deliveryOption": null,
            "rows": [],
        }]);
    }

    const findLocalProduct = (value,shouldTryToFindCarrier = true) => {
        console.log(value);
        const foundProduct = toScanRows.find(orderline => {
            console.log("orderline",orderline);

            return orderline.product.barcode === value;
        });
        if (foundProduct) {
            setMatchedProduct(foundProduct);
        } else {
            if(shouldTryToFindCarrier){
                fetchCarrierBarcode(value).unwrap()
                .then((result) => {
                    setIncCarrier(result);
                })
                .catch(err => {
                    props.setHighlightColor("red");
                })
            }
        }
    }

    useEffect(() => {
        if (!matchedProduct) return;
    
        if (matchedProduct.amount > 16) {
            setPaModalData({
                title: "Hoeveel van dit product wil je scannen?",
                content: `${matchedProduct.amount} x ${matchedProduct.product.description}`,
                value: "",
                hasInput: true,
                inputLabel: "amount",
                inputType: "number",
                confirm: confirmProductAmount
            });
            productAmountModal.setOpen(true);
        } else {
            updateCarrierAndScanRows(1);
        }
    }, [matchedProduct]);

    // const JokerClick = () => {
    //     if(carriers[carriers.length - 1].carrierId !== null && toScanRows.length === 0){
    //         setModalData({
    //             title: "Joker een voltooide order",
    //             content: `Deze order is volledig doorlopen voor de eCheck. Weet je zeker dat je deze alsnog wilt jokeren?`,
    //             value: 0,
    //             confirm: (e) => {}
    //         });
    //         confirmModal.setOpen(true);
    //     } else {
    //         setJoker();
    //     }
    // }

    // const setJoker = async () => {
    //     const response = await setJokerResponse(currentOrder.id).unwrap();
    //     NextOrder(true);
    // }

    const updateCarrierAndScanRows = (mutation) => {
        setCarriers(prev => {
            let updatedCarriers = [...prev];
            
            if (updatedCarriers.length > 0) {
                let lastCarrier = { ...updatedCarriers[updatedCarriers.length - 1] };
                const productIndexInCarrier = lastCarrier.rows.findIndex(row => row.product.barcode === matchedProduct.product.barcode);
                
                if (productIndexInCarrier !== -1) {
                    const updatedProduct = { ...lastCarrier.rows[productIndexInCarrier], amount: lastCarrier.rows[productIndexInCarrier].amount + mutation };
                    lastCarrier.rows[productIndexInCarrier] = updatedProduct;
                } else {
                    let productToAdd = { ...matchedProduct, amount: mutation };
                    lastCarrier.rows.push(productToAdd);
                }
                
                fetchDeliveryOptions(lastCarrier);
            
                updatedCarriers[updatedCarriers.length - 1] = lastCarrier;
            }
            
            return updatedCarriers;
        });

        //Removes the scan rows from the left side
        setToScanRows(prevToScanRows => 
            prevToScanRows.map(orderline => 
                orderline.product.barcode === matchedProduct.product.barcode 
                ? { ...orderline, amount: orderline.amount - mutation } 
                : orderline
            ).filter(orderline => orderline.amount > 0)
        );
        props.setHighlightColor('green');
    }


    const fetchDeliveryOptions = async (carrier) => {
        if(carriers?.length === 0) return;
        if (selectedShipper?.type === "bol" && carrier.rows.length > 0) {
            const requestBody = {
                "shipperId": selectedShipper.id,
                "items": {
                    "orderItems": carrier.rows.map(row => ({
                        "orderItemId": row.product.externalId,
                        "quantity": row.amount
                    }))
                }
            };

            
            //get index of carrier in carriers
            const carrierIndex = carriers.length === 1 ? 0 : carriers.findIndex(c => c.carrierId === carrier.carrierId);

            setCarrierForDeliveryToFetch(carrierIndex);
            setDeliveryOptionsFetchData(requestBody);
        }
    };

     useEffect(() => {
        if(incCarrier === "dummy") return;
        if (incCarrier) {
            setCarriers(prevCarriers => {
                // Copy the previous carriers array
                const updatedCarriers = [...prevCarriers];
                
                // If there are no previous carriers, just return the initial list
                if (updatedCarriers.length === 0) return updatedCarriers;
    
                // Update the last carrier
                const lastCarrier = updatedCarriers[updatedCarriers.length - 1];
                if(lastCarrier.carrierId === null){
                    lastCarrier.carrierId = incCarrier.id; 
                    lastCarrier.carrierName = incCarrier.name;
                }
                
                fetchDeliveryOptions(lastCarrier); // Fetch delivery options if shipper type is "bol" and there are products
    
                // If the last carrier has rows, add a new carrier
                if (lastCarrier.rows && lastCarrier.rows.length > 0 && toScanRows.length !== 0) {
                    // Add a new empty carrier
                    updatedCarriers.push({
                        carrierId: null,
                        carrierName: "",
                        totalProducts: 0,
                        totalWeight: 0,
                        deliveryOption: null,
                        rows: [],
                    });
                }

                // If there is a suggested carrier, check if the carrier is the same as the suggested carrier
                const color = suggestedCarrier && Object.keys(suggestedCarrier).length > 0 ? (suggestedCarrier.barcode === incCarrier.barcode ? "green" : "red") : "green";
        
                // Return the updated carriers array
                props.setHighlightColor(color);

                
                return updatedCarriers;
            });
        } else {
            props.setHighlightColor('red');
            toast.error(`Onjuiste scan`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        setIncCarrier("dummy");
    }, [incCarrier]);

    useEffect(() => {
        if(toScanRows?.length === 0 && selectedShipper && selectedShipperProfile && props.autoFinalize){
            setGoFinalize(true);
        }
    }, [carriers,toScanRows, selectedShipper, selectedShipperProfile,props.autoFinalize]);
    
    console.log(props.incData.pickingJobId);

    // A utility function to compute updated carriers with totalWeight and totalProducts
    const computeUpdatedCarriers = (prevCarriers) => {
        return prevCarriers.map(carrier => {
            let newTotalWeight = 0;
            let newTotalAmount = 0;

            carrier.rows.forEach(productRow => {
                newTotalWeight += (productRow.amount * productRow.product.weight);
                newTotalAmount += productRow.amount;
            });

            return {
                ...carrier,
                totalWeight: newTotalWeight,
                totalProducts: newTotalAmount
            };
        });
    };
    const finalize = async () => {
        if(props.carrierMandatory){
            if(carriers[carriers?.length - 1]?.carrierId === null){
                showToastMessage("warning", "Er is geen drager gekozen. Selecteer of scan een drager om af te ronden.");
                return;
            }
        }
        try {
            const response = await finalizeResponse({
                "order": currentOrder,
                "carriers": carriers,
                "shipper": selectedShipper.id,
                "shipperProfile": selectedShipperProfile.id,
                "workspace": props.selectedWorkSpace.id
            }).unwrap();

            if(response.success){
                if(response.openFiles){
                    for (let i = 0; i < response.labelContent.length; i++) {
                        const content = response.labelContent[i];
                        let url = "";
                        if(response.labelType === 'pdf'){
                            const decodedData = atob(content);
                            const arrayBuffer = new ArrayBuffer(decodedData.length);
                            const uint8Array = new Uint8Array(arrayBuffer);
                            for (let i = 0; i < decodedData.length; i++) {
                                uint8Array[i] = decodedData.charCodeAt(i);
                            }
                            const blob = new Blob([uint8Array], { type: 'application/pdf' });
                            url = URL.createObjectURL(blob);
                        } else if(response.labelType === 'link'){
                            url = content;
                        }
                        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                        if (newWindow) {
                            setTimeout(() => {
                                window.URL.revokeObjectURL(url);
                            }, 500);
                        } else {
                            window.URL.revokeObjectURL(url);
                        }
                    };
                }

                setPreviousOrder({
                    "totalAmount": totalAmount,
                    "order": currentOrder,
                    "carriers": carriers,
                    "shipper": selectedShipper,
                    "shipperProfile": selectedShipperProfile,
                    "workspace": props.selectedWorkSpace.id
                })

                await resetScreen();

            } else {
            
                showToastMessage("error", response.message);
            }
        } catch (error) {
            showToastMessage("error", error.data?.error || error.message);
        }
    }

    const resetScreen = async () => {
        setTimer(null);
        setFinalizeReady(false);
        setShowSlide(false);
        setGoFinalize(false); 
        setCurrentOrder(null);
        setSuggestedCarrier(null);
        setToScanRows(null);
        setCurrentOrderWarehouseId(null);
        setMatchedProduct(null);
        setSelectedOrder(null);
        
        await getMyJob({
            id:props.incData.pickingJobId,
            workspaceId:props.selectedWorkSpace.id
        }).unwrap()
        .then(result => {
            const allEcheckedEqualAmount = result.locations.length === 0;
            if (allEcheckedEqualAmount) {
                // If true, hide Echeck
                props.setShowEcheck(false);
                // if(!props.jobsLoading && !props.jobsFetching){
                //     props.refetchJobs();
                // }
            } else {
                props.setHighlightColor("green");
                // If false, set the incData to the result
                props.setIncData(result);
            }
        })
        .catch(error => {
            console.error("err",error);
            props.setHighlightColor('red');
            toast.error(error.data?.message ??  `Scan een geldige krat of pickbon`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }
    const selectLabel = (carrierIndex, index) => {
        setCarriers(prev => {
            const updatedCarriers = prev.map((carrier, idx) => 
                idx === carrierIndex ? { ...carrier, deliveryOption: deliveryOptions[carrierIndex][index] } : carrier
            );
            return updatedCarriers;
        });
    };

    const FinalizeClick = () => {
        if(!selectedShipper || !selectedShipperProfile) return;
        if(toScanRows.length === 0){
            if(selectedShipper.type === "bol"){
                if(carriers.every(carrier => carrier.deliveryOption !== null)){

                    finalize();
                }
            } else {

                finalize();
            }
        } else {
            setModalData({
                title: "Afronden niet mogelijk",
                content: `Je kunt deze order nog niet afronden omdat nog niet alle producten zijn gescant`,
                value: 0,
                confirm: (e) => {}
            });
            confirmModal.setOpen(true);
        }
    }

    useEffect(() => {;
        const updatedCarriers = computeUpdatedCarriers(carriers);
        
        // Check if the new calculated values differ from the current carriers
        if (JSON.stringify(updatedCarriers) !== JSON.stringify(carriers)) {
            setCarriers(updatedCarriers);
        }

        const overallTotalWeight = updatedCarriers.reduce((acc, curr) => acc + curr.totalWeight, 0);
        const overallTotalAmount = updatedCarriers.reduce((acc, curr) => acc + curr.totalProducts, 0);

        setTotalWeight(overallTotalWeight);
        setTotalAmount(overallTotalAmount);

    }, [carriers]);


    const [filterBySelectedWorkSpace, setFilterBySelectedWorkSpace] = useState(true);
    const toggleFilterBySelectedWorkSpace = (event, newValue) => {
        // Ensure the newValue is boolean (true or false)
        if (newValue !== null) {
            setFilterBySelectedWorkSpace(newValue); // Directly set the newValue (boolean)
        }
    };

    const { data: carriersSelect } = useGetCarriersSelectQuery();
    const selectManualCarrier = (selected) => {
        var carrier = carriersSelect[selected];
        setIncCarrier(carrier);
    }
    const OwnCarrierClick = () => {
        var carrier = {id: -1,
        name: t("own_carrier")};
        setIncCarrier(carrier);
    }

    const [shipmentHighlightColor, setShipmentHighlightColor] = useState('none');
    const [highlightColor, setHighlightColor] = useState('none');

    useEffect(() => {
        if (highlightColor !== 'none') {
            setTimeout(() => setHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [highlightColor]);

    useEffect(() => {
        if (shipmentHighlightColor !== 'none') {
            setTimeout(() => setShipmentHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [shipmentHighlightColor]);

    return(
        <Box
            sx={{
                flex:1,
                marginBottom:3,
                position:"relative",
                display:"flex",
                overflow:"hidden",
                "@media screen and (max-width: 1080px)" : {
                    flexDirection:'column'
                }
            }}
        >
            <Box 
                sx={{
                    overflow:"auto",
                    "@media screen and (max-width: 1080px)" : {
                        display:'flex'
                    }
                }}
            >
                <ButtonBase
                    onClick={() => {
                        if(selectedOrder !== null) {
                            setSelectedOrder(null);
                        }else {
                            if(toScanRows === null || toScanRows.length === 0){
                                props.setShowEcheck(false);
                            } else {
                                setCloseModalData({
                                    title: "Niet gescande regels",
                                    content: `Het sluiten van deze bestelling is niet aan te raden omdat et er nog niet ge-echeckte regels in deze bestelling zitten.`,
                                    value: 0,
                                    confirm: (e) => {}
                                });
                                confirmCloseModal.setOpen(true);
                            }
                            // if(!props.jobsLoading && !props.jobsFetching){
                            //     props.refetchJobs();
                            // }
                        }
                    }}
                    sx={{
                        ml:"-16px",
                        fontWeight:"bold",
                        p:2,
                        color:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]
                    }}
                >
                    {selectedOrder !== null ? t("go_back") : t("close")}
                </ButtonBase>
                
                {currentOrder !== null &&
                    <Box
                        sx={{
                            // display:"flex",
                            display:"flex",
                            "@media screen and (max-width: 1080px)" : {
                                flexDirection:'column',
                                justifyContent:"center",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:380,
                                background:theme.palette.mode === "dark" ? colors.pink[100] : colors.pink[100],
                                padding:2,
                                borderRadius:4,
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    // gap:1.5,
                                    flexDirection:'column',
                                    mb:3,
                                }}
                            >
                                <Heading color={theme.palette.mode === "dark" ? colors.pink[400] : colors.pink[400]} sx={{mb:0.5}}>{t("to_scan_in_order")}</Heading>

                                {toScanRows?.map((row, index) => {
                                    return (
                                        <Box key={index}>
                                            <Box
                                                sx={{
                                                    mt:1.5,
                                                    flex:1,
                                                    display:"flex",
                                                    background:theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[0],
                                                    border: theme.palette.mode === "light" ? `1px solid ${colors.grey[100]}` :null,
                                                    boxShadow: theme.palette.mode === "light" ? "0 2px 4px rgba(49,65,95,.15)" : null,
                                                    borderRadius:3,
                                                    padding:1,
                                                    alignItems:"center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                    <Box
                                                        sx={{
                                                            display:"flex",

                                                        }}
                                                    >
                                                        <ShLabel size={32} fitted palette="normal" variant={"orange"}>
                                                            {row.amount}
                                                        </ShLabel>
                                                        <Box
                                                            sx={{
                                                                ml:2,
                                                                display:"flex",
                                                                flexDirection:'column',
                                                            }}
                                                        >
                                                            <Text sx={{whiteSpace:"pre"}} bold>
                                                                {row.product.barcode}
                                                            </Text>
                                                            <Text >
                                                                {row.product.description}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                        {/* <Text light>
                                                            {sku}
                                                        </Text> */}
                                                {/* {scanlabel} */}
                                            </Box>
                                        </Box>
                                    )
                                })}

                                {toScanRows?.length === 0 &&
                                    <Box
                                        sx={{
                                            flex:1,
                                            display:"flex",
                                            background:theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[0],
                                            border: theme.palette.mode === "light" ? `1px solid ${colors.grey[100]}` :null,
                                            boxShadow: theme.palette.mode === "light" ? "0 2px 4px rgba(49,65,95,.15)" : null,
                                            borderRadius:3,
                                            padding:1,
                                            alignItems:"center",
                                            justifyContent: "center",
                                            opacity:0.7,
                                        }}
                                    >
                                        <Text semibold sx={{marginLeft: 2,
                                            opacity:0.7,}}>
                                            {t("everything_scanned")}
                                        </Text>
                                    </Box>
                                }
                            </Box>

                            <Box
                                sx={{
                                    flex: 1,
                                    background: theme.palette.mode === "dark" ? colors.pink[100] : colors.pink[100],
                                    borderRadius: 4,
                                    // marginTop: 3,
                                    paddingTop:4,
                                    overflow: "hidden",
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingBottom: 3,
                                    justifyContent: 'center',  // To center contents vertically
                                    alignItems: 'center',      // To center contents horizontally
                                }}
                            >
                                <img 
                                    src="/img/barcode.svg" 
                                    alt="Barcode" 
                                    style={{
                                        width: '30%', 
                                        height: '30%', 
                                        objectFit: 'contain',
                                        animation: 'pulsate 1.5s infinite',
                                        marginBottom:"16px",
                                    }} 
                                />

                                <Box display="flex"
                                    height="32px"
                                    // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        background:theme.palette.mode === "dark" ? colors.grey[800] : colors.txt["white"],
                                        marginTop:0.5,
                                        marginBottom:0.5,
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <InputBase 
                                        inputRef={inputRef}
                                        sx={{
                                            ml: 2, 
                                            flex:1, 
                                            width: 248, 
                                            textAlign: 'center', 
                                            justifyContent: 'center'
                                        }} 
                                        placeholder={t("scan_type_barcode")}
                                        onKeyDown={handleKeyDown}
                                        autoFocus={true}
                                    />
                                </Box>
                            </Box>

                        </Box>

                        <Box
                            width={120}
                            sx={{
                                display: "flex",
                                paddingBottom: 3,
                                justifyContent: "center",
                                alignItems: "center",
                                color: colors.grey[300],
                                position: "relative", // For controlling the layout and positioning
                                "@media screen and (max-width: 1080px)": {
                                    transform: "rotate(90deg)",
                                    width: 380,
                                },
                            }}
                        >
                            {/* Wrapper Box for animation */}
                            <Box
                                sx={{
                                    overflow:"hidden",
                                    width: "100%",
                                    // height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    animation: "moveArrow 1.5s infinite ease-in-out", // Animation applied to wrapper box
                                    "@keyframes moveArrow": {
                                        "0%": {
                                        transform: "translateX(0)", // Start position
                                        },
                                        "50%": {
                                        transform: "translateX(15%)", // Move to the right (adjust as needed)
                                        },
                                        "100%": {
                                        transform: "translateX(0)", // Return to the start
                                        }
                                    },
                                }}
                            >
                                <img
                                    className="supra-arrow"  // Keep the class for potential future use
                                    src="/img/arrow.svg"
                                    alt="Barcode"
                                    style={{
                                        width: "40%",   // Image size
                                        height: "40%",  // Image size
                                        objectFit: "contain", // Ensures the image fits the container
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                }

                <Box
                    sx={{
                        display:"flex",
                        "@media screen and (max-width: 1080px)" : {
                            flexDirection:'column',
                            justifyContent:"center",
                        }
                    }}
                >
                    <Box
                        sx={{
                            width:380,
                            mt:currentOrder !== null ? 4 : 0,
                            borderRadius:4,
                        }}
                    >
                        {toScanLocations?.length > 0 &&
                            <Box
                                sx={currentOrder !== null ? {
                                    userSelect:"none",
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    position:'relative',
                                } : {
                                    userSelect:"unset",
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    position:'relative',
                                    minHeight:selectedOrder !== null ?  180 : null,
                                }}
                            >
                                <Subheading
                                    sx={{
                                        opacity: currentOrder !== null || selectedOrder !== null ? 0.35 : 1,
                                    }}
                                >
                                    {t("to_process")}
                                </Subheading>
                                {toScanLocations?.map((row, index) => {
                                    // console.log("row",row.orderId);
                                    //if(row.amountEchecked >= row.amount) return null;
                                    return (
                                        <Box
                                            key={"scan-prod"+index}
                                            sx={{
                                                opacity: currentOrder !== null || selectedOrder !== null ? 0.35 : 1,
                                                width:1,
                                                display:'flex',
                                                boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                                border:`1px solid ${colors.grey[100]}`,
                                                p:2,
                                                borderRadius:4,
                                                justifyContent:"space-between",
                                                alignItems:"flex-start",
                                                gap:2,
                                                // pointerEvents: row.amountEchecked === row.amount ? "none" : null,
                                                // userSelect: row.amountEchecked === row.amount ? "none" : null,
                                                background: theme.palette.mode === 'dark' ?  colors.grey[300] : colors.grey[0]
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                }}
                                            >
                                                <Text bold>
                                                    {row.location}
                                                </Text>
                                                <br/>
                                                <Text>
                                                    {row.barcode}
                                                </Text>
                                            </Box>

                                            {/* <Box
                                                sx={{
                                                    flexShrink:0,
                                                    height:32,
                                                    px:2,
                                                    display:"flex",
                                                    justifyContent:'center',
                                                    alignItems:'center',
                                                    background: row.amountEchecked === row.amount ? colors.green[100] : theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100],
                                                    fontFamily:"Roboto, sans-serif",
                                                    color: row.amountEchecked === row.amount ? colors.green[400] : colors.txt["primary"],
                                                    borderRadius:4,
                                                    border: `1px solid ${row.amountEchecked === row.amount ? colors.green[400] : "transparent"}`
                                                }}
                                            >
                                                {row.amountEchecked} / <b>{row.amount}</b>


                                            </Box> */}

                                        </Box>
                                    );
                                })}

                                {selectedOrder !== null &&
                                
                                <Box
                                    sx={{
                                        position:'absolute',
                                        left:0,
                                        top:32,
                                        bottom:0,
                                        width:1,
                                        backdropFilter:"blur(1px)",
                                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                                        border:`1px solid ${colors.grey[200]}`,
                                        borderRadius:4,
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        textAlign:'center',
                                        px:3,
                                        minHeight:120,
                                        // background:"orange"
                                    }}
                                >
                                    <Text align="center">
                                        Om verder te gaan met verwerken druk dan hierboven op <b>"Ga terug"</b>.
                                    </Text>
                                </Box>
                                }
                                
                            </Box>
                        }

                        
                        {previousOrder !== null &&
                            <Box
                                sx={{
                                    mt:3,
                                    // display:"flex",
                                    display:"flex",
                                    "@media screen and (max-width: 1080px)" : {
                                        flexDirection:'column',
                                        justifyContent:"center",
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:380,
                                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                                        padding:2,
                                        borderRadius:4,
                                    }}
                                >

                                    <Box
                                        sx={{
                                            flex: 1,
                                            background: theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                                            borderRadius: 4,
                                            // marginTop: 3,
                                            paddingTop:4,
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "column",
                                            paddingBottom: 3,
                                            justifyContent: 'center',  // To center contents vertically
                                            alignItems: 'center',      // To center contents horizontally
                                        }}
                                    >
                                        <img 
                                            src="/img/barcode.svg" 
                                            alt="Barcode" 
                                            style={{
                                                width: '30%', 
                                                height: '30%', 
                                                objectFit: 'contain',
                                                animation: 'pulsate 1.5s infinite'
                                            }} 
                                        />

                                        <Box display="flex"
                                            height="32px"
                                            // maxWidth="400px"
                                            borderRadius="20px"
                                            sx={{
                                                mt:2,
                                                background: theme.palette.mode === "dark" ? colors.grey[100] : colors.grey[200],
                                                marginTop:0.5,
                                                marginBottom:0.5,
                                                justifyContent:"space-between",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flexDirection:"column"
                                                }
                                            }}
                                        >
                                            <InputBase 
                                                inputRef={inputRefWhenOneOrderWasAlreadyScanned}
                                                sx={{
                                                    ml: 2, 
                                                    flex:1, 
                                                    width: 248, 
                                                    textAlign: 'center', 
                                                    justifyContent: 'center',
                                                    "&:focus" : {
                                                        border:`1px solid orange`,
                                                    }
                                                }} 
                                                placeholder={t("scan_type_barcode")}
                                                onKeyDown={handleKeyDown}
                                                autoFocus={true}
                                            />
                                        </Box>
                                    </Box>

                                </Box>

                                
                            </Box>
                        }

                        {props.incData?.shippedOrders.length > 0 &&
                            <Box
                                sx={currentOrder !== null ? {
                                    opacity: currentOrder !== null || selectedOrder !== null ? 0.35 : 1,
                                    mt:5,
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    userSelect:"none",
                                } : {
                                    opacity:  1,
                                    mt:5,
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    userSelect:"unset",

                                }}
                            >
                                <Box
                                    sx={{
                                        display:'flex',
                                        justifyContent:'space-between',
                                        alignItems:'center'
                                    }}
                                >
                                    <Subheading>
                                        {t("processed")}
                                    </Subheading>

                                    <Box>

                                        <ToggleButtonGroup
                                            color={filterBySelectedWorkSpace ? "green" : "red"}
                                            value={filterBySelectedWorkSpace}
                                            exclusive
                                            onChange={toggleFilterBySelectedWorkSpace}
                                            aria-label="Active"

                                            className={cx(`${filterBySelectedWorkSpace}`, {
                                                'active': filterBySelectedWorkSpace === "true"
                                            })}
                                            sx={{
                                                background: colors.grey[100],
                                                padding:0.5,
                                                borderRadius:5,
                                                transition:"250ms",
                                                "&.active" : {
                                                    background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                                                    transition:"250ms",
                                                },
                                            }}
                                        >
                                            <ToggleButton
                                                sx={{
                                                    padding: 0,
                                                    paddingX: 2,
                                                    border: 0,
                                                    lineHeight: "32px",
                                                    textTransform: "unset",
                                                    borderRadius: "32px !important",
                                                    color:filterBySelectedWorkSpace ? theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]:  "#fff",  // White text
                                                    background: filterBySelectedWorkSpace === false ? colors.grey[400] : "transparent", // Red background if inactive
                                                    transition: "250ms",
                                                    "&.Mui-selected": {
                                                        background: colors.grey[400], 
                                                        color: "#fff", 
                                                        fontWeight:"bold",
                                                    },
                                                    "&.Mui-selected:hover": {
                                                        background:theme.palette.mode === "dark" ? colors.grey[600]: "#1F293D",
                                                    },
                                                }}
                                                value={false}
                                            >
                                                {t('all')}
                                            </ToggleButton>
                                            <ToggleButton
                                                sx={{
                                                    padding: 0,
                                                    paddingX: 2,
                                                    border: 0,
                                                    lineHeight: "32px",
                                                    textTransform: "unset",
                                                    borderRadius: "32px !important",
                                                    color: theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[400], // White text
                                                    background: filterBySelectedWorkSpace === true ? theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] : "transparent", // Green background if active
                                                    transition: "250ms",
                                                    "&.Mui-selected": {
                                                        background: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400], // Green background if selected
                                                        color: theme.palette.mode === "dark" ? colors.txt["primaryDark"] :  colors.txt["white"], // White text
                                                        fontWeight:"bold",
                                                    },
                                                    "&.Mui-selected:hover": {
                                                        background:theme.palette.mode === "dark" ? "#5C95FF" : "#004CDB"
                                                    },
                                                }}
                                                value={true}
                                            >
                                                {props.selectedWorkSpace.name}
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                                
                                <Box>
                                    <ShippedOrders
                                        filterBySelectedWorkSpace={filterBySelectedWorkSpace}
                                        shippedOrders={props.incData.shippedOrders}
                                        currentOrder={currentOrder}
                                        setShipmentHighlightColor={setShipmentHighlightColor}
                                        setSelectedOrder={setSelectedOrder}
                                        workspaceId={props.selectedWorkSpace.id}
                                    />
                                </Box>

                                
                            </Box>
                        }

                    </Box>

                    <Box
                        width={120}
                        sx={{
                            opacity:0,
                            visibility:"hidden",
                            display: "flex",
                            // flexDirection: "column",
                            paddingBottom: 3,
                            justifyContent: 'center',  // To center contents vertically
                            alignItems: 'center',
                            color: colors.grey[300],
                          
                            
                        }}
                    >
                        <img
                            className="supra-arrow"
                            src="/img/arrow.svg" 
                            alt="Barcode" 
                            style={{
                               
                            }}
                        />
                    </Box>
                 
                </Box>
            </Box>
            
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],

                    flex:1,
                    p:2,
                    borderRadius:6,
                    // borderTopLeftRadius:24,
                    // borderTopRightRadius:24,
                    flexDirection: "column",
                    display:"flex",
                }}
            >
                {currentOrder !== null ?
                    <EcheckView
                        totalAmount={totalAmount}
                        carriers={carriers}
                        deliveryOptions={deliveryOptions}
                        selectLabel={selectLabel}
                        selectManualCarrier={selectManualCarrier}
                        OwnCarrierClick={OwnCarrierClick}
                        carriersSelect={carriersSelect}
                        toScanRows={toScanRows}
                        showOptions={showOptions}
                        selectedShipper={selectedShipper}
                        selectedShipperProfile={selectedShipperProfile}
                        currentOrder={currentOrder}
                        shippers={shippers}
                        selectShipper={selectShipper}
                        selectShipperProfile={selectShipperProfile}
                        showSlide={showSlide}
                        finalizeLoading={finalizeLoading}
                        finalizeFetching={finalizeFetching}
                    />
                :
                selectedOrder !== null ?
                    <SelectedOrderView
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        resetScreen={resetScreen}
                        setHighlightColor={props.setHighlightColor}
                        selectedWorkSpace={props.selectedWorkSpace}
                    />
                :

                previousOrder !== null ? 
                    <PreviousOrderEchecked
                        previousOrder={previousOrder}
                    />
                :
                    <Box
                        sx={{
                            flex:1,
                            flexDirection: "column",
                            display:"flex",
                            p:2,
                            borderRadius:4,
                            position:'relative',
                            // borderTopLeftRadius:16,
                            // borderTopRightRadius:16,
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],

                        }}
                    >

                        <Box
                            sx={{
                                flex: 1,
                                borderRadius: 4,
                                // marginTop: 3,
                                paddingTop:4,
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: 3,
                                justifyContent: 'center',  // To center contents vertically
                                alignItems: 'center',      // To center contents horizontally
                            }}
                        >
                            <img 
                                src="/img/barcode.svg" 
                                alt="Barcode" 
                                style={{
                                    width: '30%', 
                                    height: '30%', 
                                    objectFit: 'contain',
                                    animation: 'pulsate 1.5s infinite'
                                }} 
                            />

                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase 
                                    sx={{
                                        ml: 2, 
                                        flex:1, 
                                        width: 248, 
                                        textAlign: 'center', 
                                        justifyContent: 'center'
                                    }} 
                                    placeholder={t("scan_type_barcode")}
                                    onKeyDown={handleKeyDown}
                                    autoFocus={true}
                                />
                            </Box>
                        </Box>

                    </Box>
                }

                {currentOrder !== null &&
                    <Box
                        sx={{
                            mt:3,
                            display:"flex",
                            justifyContent:"flex-end",
                            alignItems:"center",
                        }}
                    >
                        {/* <ButtonGroup
                            version={2}
                        >
                            <DropdownBase
                                transformOrigin={{
                                    vertical: "bottom"
                                }}
                                anchorOrigin={{
                                    vertical:"top"
                                }}
                                displayName={"name"} 
                                onChange={(selected) => selectManualCarrier(selected)}
                                noSelection={t("choose_carrier")} //Custom text when nothing is selected
                                options={carriersSelect}
                                selected={-1}
                                closeOnSelection
                                resetOnSelection
                            >
                                <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained">{t("choose_carrier")}</Shbutton>
                            </DropdownBase>

                            <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained" onClick={() => OwnCarrierClick()}>{t("own_carrier")}</Shbutton>
                        </ButtonGroup> */}
                        <ButtonGroup version={2}>
                            <Shbutton className={"group-btn"} isLoading={finalizeLoading || finalizeFetching} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained">Print pakbon</Shbutton>
                            
                            {/* <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained" onClick={() => JokerClick()}>Joker</Shbutton> */}
                            <Shbutton className={"group-btn"} isLoading={finalizeLoading || finalizeFetching} color={"green"} variant="contained" onClick={() => FinalizeClick()}>Afronden</Shbutton>
                        </ButtonGroup>
                    </Box>
                }
            </Box>


            <MConfirmationWithValue
                open={confirmModal.open}
                handleClose={() => confirmModal.setOpen(false)}
                data={confirmModal.data}
                confirm={confirmModal.confirm}
                // barcodeView={barcodeView}
            />


            <MConfirmationWithValue
                open={confirmCloseModal.open}
                handleClose={() => confirmCloseModal.setOpen(false)}
                data={confirmCloseModal.data}
                confirm={confirmCloseModal.confirm}
                // barcodeView={barcodeView}
            />
            <MConfirmationWithValue
                open={productAmountModal.open}
                handleClose={() => productAmountModal.setOpen(false)}
                data={productAmountModal.data}
                confirm={productAmountModal.confirm}
            />
        </Box>
    )
}

export default LocationsEcheckView;
