
import WarehouseIcon from '@mui/icons-material/Warehouse';


export const relationOrdersConfig = (orderTotals) => {
    return {
        orders:  {
            title: 'all_orders',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
            ...(orderTotals && {count: orderTotals.open})
        },
        ordersActionRequired: {
            title: 'action_required',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
            ...(orderTotals && {count: orderTotals.action})
        },
        ordersShipped: {
            title: 'shipped',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
            ...(orderTotals && {count: orderTotals.shipped})
        },
        ordersCancelled: {
            title: 'cancelled',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
            ...(orderTotals && {count: orderTotals.cancelled})
        },
    };
};
