import { Box, ButtonBase, Slide, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import Subheading from "../../../../../components/theme/text/Subheading";
import ContainerView from "../components/EcheckCarrier";
import { DropdownBase } from "../../../../../components/theme/dropdowns/Dropdown";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { getShipperColorSettingsByType } from "../../../../../components/Streams/StreamShipperSettings";
import Text from "../../../../../components/theme/text/Text";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShSwitch from "../../../../../components/theme/inputs/ShSwitch";
import Truck from "../components/truck/Truck";
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';

const EcheckView = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const ShipperLogoMap = {
        postnl: PostNLLogo,
        dhl: DhlLogo,
        dpd: DpdLogo,
        bol: BolLogo,
      };

    const renderShipperLogo = (shipperType) => {
        // Get the logo component based on shipper type
        const LogoComponent = ShipperLogoMap[shipperType];

        // Render the logo component if it exists, or null otherwise
        return LogoComponent ? <LogoComponent className={shipperType} /> : null;

    };

    // if(selectedOrderSelectShipper) {
    //     return getShipperColorSettingsByType(selectedOrderSelectShipper?.type).background;
    // }
    return (

        <Box
            sx={{
                flex:1,
                flexDirection: "column",
                display:"flex",
                p:2,
                borderRadius:4,
                position:'relative',
                // borderTopLeftRadius:16,
                // borderTopRightRadius:16,
                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],

            }}
        >

            {props.carriers.length >1 ||  (props.carriers.length === 1 && props.carriers[0].rows.length > 1) ?
                <Box
                    sx={{
                        display:"flex"
                    }}
                >
                    <Subheading>{props.totalAmount}</Subheading>&nbsp;
                    <Subheading light>{props.totalAmount > 1 ? t("products") : t("product")} </Subheading>&nbsp;
                    <Subheading semibold variant="purple">{t("processed")}</Subheading>&nbsp;
                    <Subheading light>in</Subheading>&nbsp;
                    <Subheading>{props.carriers?.length}</Subheading>&nbsp;
                    <Subheading light>{t("carriers")}</Subheading>
                </Box>
                :
                null
            }
            
            <Box
                sx={{
                    mt:2,
                    display:'flex',
                    gap:3,
                }}
            >
                <Box 
                    sx={{
                        flex:1,
                        display:'flex',
                        flexDirection:"column",
                        gap:2,
                    }}
                >
                    {/* {PickedView()} */}
                    {props.carriers && props.carriers?.map((carrier, carrierIndex) => (
                        <ContainerView
                            deliveryOptions={props.deliveryOptions}
                            selectLabel={props.selectLabel}
                            selectManualCarrier={props.selectManualCarrier}
                            OwnCarrierClick={props.OwnCarrierClick}
                            carriersSelect={props.carriersSelect}
                            carrier={carrier}
                            carrierIndex={carrierIndex}
                        />
                    ))}
                    {((props.carriers?.[props.carriers.length-1].rows.length !== 0 && props.carriers[props.carriers.length-1].carrierName !== "") && props.toScanRows.length > 0) &&
                        <Box
                            sx={{
                                // mt:3,
                                background: theme.palette.mode === "dark" ? colors.grey[500] : colors.bg["tertiary"],
                                borderRadius: 4,
                                overflow: "hidden",
                                display: "flex",
                                paddingBottom: 3,
                                postion:"relative",
                                px:2,
                                py:2,
                                gap:2,
                            }}
                        >
                            <DropdownBase
                                key={props.carriers?.length}
                                transformOrigin={{
                                    vertical: "bottom"
                                }}
                                anchorOrigin={{
                                    vertical:"top"
                                }}
                                displayName={"name"} 
                                onChange={(selected) => props.selectManualCarrier(selected)}
                                noSelection={t("choose_carrier")} //Custom text when nothing is selected
                                options={props.carriersSelect}
                                selected={-1}
                                closeOnSelection
                                resetOnSelection
                            >
                                <Shbutton className={"group-btn"} sx={{whiteSpace:"nowrap"}} color={"primaryGrey"} variant="contained">{t("choose_carrier")}</Shbutton>
                            </DropdownBase>

                            <Shbutton className={"group-btn"} sx={{whiteSpace:"nowrap"}} color={"primaryGrey"} variant="contained" onClick={() => props.OwnCarrierClick()}>{t("own_carrier")}</Shbutton>
                        </Box>
                    }
                </Box>
                

                <Box
                    sx={{
                        minHeight:120,
                        // height:50,
                        position:'sticky',
                        top:0,
                        borderRadius:4,
                        p:1.5,
                        flex:1,
                        boxShadow:`0px 2px 4px ${getShipperColorSettingsByType(props.selectedShipper.type).streamrulehover}`,
                        border:`1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100]}`,
                        // background: theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0],
                        background: getShipperColorSettingsByType(props.selectedShipper.type).background,
                        transition: 'background-color 0.5s',
                    }}
                >

                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                pl:1.5,
                                pt:1.5,
                            }}
                        >
                            <Subheading
                                color={
                                    tokens("white").txt["primary"]
                                }
                            >
                                {props.currentOrder?.deliveryName}
                            </Subheading>
                            
                        </Box>
                        <Box
                            sx={{
                                mt:1.5,
                                mr:1.5,
                                mb:1,
                                height:40,
                                display:"flex",
                                alignItems:'center',
                                px:2,
                                pl:0.5,
                                gap:1.5,
                                borderRadius:5,
                                background:getShipperColorSettingsByType(props.selectedShipper.type).background,
                                color: getShipperColorSettingsByType(props.selectedShipper.type).base,
                                fontWeight:"bold",
                                border: `1px solid ${getShipperColorSettingsByType(props.selectedShipper.type).base}`,
                                "& .postnl" : {
                                    width:24,
                                    height:24,
                                },
                
                                "& .dpd" : {
                                    width:24,
                                    height:24,
                                },
                                "& .dhl" : {
                                    width:24,
                                    height:24,
                                },
                                "& .bol" : {
                                    width:32,
                                    // width:24,
                                    height:16,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    height:32,
                                    width:32,
                                    borderRadius:4,
                                    display:"flex",
                                    alignItems:'center',
                                    justifyContent:'center',
                                    background:getShipperColorSettingsByType(props.selectedShipper.type).streamrule,
                                    fill:"white",
                                    "& svg" : {
                                        // fill:"white",
                                    },
                                    "& svg *" : {
                                        // fill:"white",
                                    }
                                }}

                            >
                                {renderShipperLogo(props.selectedShipper.type)}
                            </Box>
                            {props.selectedShipper.name}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p:1.5,
                            mb:1,
                        }}
                    >

                        <Text
                            color={
                                tokens("white").txt["primary"]
                            }
                        >
                            {props.currentOrder?.shippingAddress?.addressLine1}
                        </Text>
                        <br/>

                        
                        <Text
                            color={
                                tokens("white").txt["primary"]
                            }
                        >
                            {props.currentOrder?.shippingAddress?.postalCode}, {props.currentOrder?.shippingAddress?.city}
                        </Text>
                        <br/>
                        
                        <Text
                            color={
                                tokens("white").txt["primary"]
                            }
                        >
                            {props.currentOrder?.shippingAddress?.state}, {props.currentOrder?.shippingAddress?.country}
                        </Text>
                        <br/>
                    </Box>
                    <Box
                        sx={{
                            background:getShipperColorSettingsByType(props.selectedShipper.type).streamrule,
                            borderRadius:3,
                            p:1.5,
                        }}
                    >
                        <Box>
                            <Text color={tokens("white").txt["primary"]} >{t("carrier_advice")}:</Text><br/>
                            <Text color={getShipperColorSettingsByType(props.selectedShipper.type).base} bold>
                                {props.currentOrder?.suggestedCarrier && props.currentOrder?.suggestedCarrier.id > 0 ? 
                                    props.currentOrder?.suggestedCarrier.name : 
                                    "Geen drageradvies"
                                }
                            </Text>
                        </Box>
                        <Box
                            sx={{
                                mt:2,
                            }}
                        >
                            
                            <Box
                                sx={{
                                }}
                            >
                                <Text color={tokens("white").txt["primary"]}>{t("shipper")}:</Text>
                                <Box 
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:'space-between',
                                    }}
                                >
                                    <Text color={getShipperColorSettingsByType(props.selectedShipper.type).base} bold>{props.selectedShipper?.name}</Text>
                                    {props.currentOrder?.deliveryMethod !== "vvb" &&
                                    <ButtonBase
                                        sx={{
                                            ml:2,
                                            width:32,
                                            height:32,
                                            // background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                                            background:getShipperColorSettingsByType(props.selectedShipper.type).base,
                                            color:getShipperColorSettingsByType(props.selectedShipper.type).background,
                                            borderRadius:5,
                                        }}
                                    >
                                        <DropdownBase
                                            transformOrigin={{
                                                vertical: "bottom"
                                            }}
                                            anchorOrigin={{
                                                vertical:"top"
                                            }}
                                            displayName={"name"} 
                                            onChange={(selected) => props.selectShipper(selected)}
                                            noSelection={""} //Custom text when nothing is selected
                                            options={props.shippers?.filter(shipper => shipper.type !== 'bol' || props.currentOrder?.webshopcredentials?.webshop_type === 'bol')}
                                            selected={-1}
                                            closeOnSelection
                                            resetOnSelection
                                        >
                                            <EditOutlinedIcon/>
                                        </DropdownBase>
                                    </ButtonBase>
                                    }
                                </Box>
                            </Box>
                            
                        </Box>
                        {props.selectedShipper?.type !== "bol" && (
                            <Box
                                sx={{
                                    mt:2,
                                }}
                            >
                                <Box >
                                    <Text color={tokens("white").txt["primary"]}>{t("shipperprofile")}:</Text><br/>
                                    <Box
                                        sx={{
                                            display:"flex",
                                            justifyContent:'space-between',
                                            alignItems:'center',
                                        }}
                                    >
                                        <Text color={getShipperColorSettingsByType(props.selectedShipper.type).base} bold>{props.selectedShipperProfile?.name}</Text>
                                        <ButtonBase
                                            sx={{
                                                width:32,
                                                height:32,
                                                background:getShipperColorSettingsByType(props.selectedShipper.type).base,
                                                color:getShipperColorSettingsByType(props.selectedShipper.type).background,
                                                borderRadius:5,
                                            }}
                                        >
                                            <DropdownBase
                                                transformOrigin={{
                                                    vertical: "bottom"
                                                }}
                                                anchorOrigin={{
                                                    vertical:"top"
                                                }}
                                                displayName={"name"} 
                                                onChange={(selected) => props.selectShipperProfile(selected)}
                                                noSelection={""} //Custom text when nothing is selected
                                                options={props.selectedShipper?.shipperProfiles}
                                                selected={-1}
                                                closeOnSelection
                                                resetOnSelection
                                            >
                                                <EditOutlinedIcon/>
                                            </DropdownBase>
                                        </ButtonBase>
                                    </Box>
                                </Box>
                            
                            </Box>
                        )}
                        {props.showOptions && 
                            <Box
                                sx={{
                                    mt:2,
                                    borderRadius:3,
                                    mx:-1.5,
                                    px:1.5,
                                    py:1,
                                    background:theme.palette.mode === "dark" ? colors.grey[400]: colors.grey[50],
                                    display:"flex",
                                    flexDirection:'column',
                                    gap:1,
                                    overflow:"hidden"
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:2
                                    }}
                                >
                                    <Text noBreak>Print pakbon per drager</Text>
                                    <ShSwitch/>
                                </Box>

                                <Box
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        gap:2
                                    }}
                                >
                                    <Text noBreak>Print pallet pakbonnen</Text>
                                    <ShSwitch/>
                                </Box>

                                <Box
                                    sx={{
                                        display:"flex",
                                        // alignItems:"center",
                                        flexDirection:'column'
                                        // gap:2
                                    }}
                                >
                                    <Text noBreak>Print pakbon met draagnummers na voltooien orderr</Text>
                                    <ShSwitch/>
                                </Box>
                            </Box> 
                        }
                    </Box>

                </Box>
            </Box>

            <Box
                sx={{
                    width:1,
                    mt:"auto",
                    mx:"auto",
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    mb:4,
                }}
            >
                <Box
                    sx={{
                        position:'absolute',
                        width:"60%",
                    }}
                >
                    <Slide
                        direction="up" 
                        // in={finalizeLoading || finalizeFetching}
                        in={props.showSlide}
                        // in
                    >
                        <Box
                            sx={{ 
                                borderRadius:8,
                                background:"#fff",
                                width:1,
                                height:65,
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:'center',
                                px:3,
                                position:'relative',
                                zIndex:1,
                                overflow:'visible',
                                "&:before" : {
                                    position:'absolute',
                                    content:"''",
                                    left:6,
                                    right:6,
                                    top:6,
                                    bottom:6,
                                    zIndex:-1,
                                    borderRadius:12,
                                    filter:"blur(12px)",
                                    background: 'linear-gradient(var(--gradient-angle), #31415f, #0D61FF, #C2D7FF)',
                                    animation: 'rotation 5s linear infinite'
                                },
                                "&:after" : {
                                    position:'absolute',
                                    content:"''",
                                    left:0,
                                    right:0,
                                    top:0,
                                    bottom:0,
                                    zIndex:-1,
                                    borderRadius:12,
                                    background:'#fff'
                                }
                            }}
                        >
                            <Box width={60} />
                            <Subheading>
                                {t('e_check_you_have_5_seconds')}
                            </Subheading>
                            <Box width={60} />
                        </Box>
                    </Slide>
                </Box>

                <Box
                    sx={{
                        position:'absolute',
                        width:"60%",
                    }}
                >
                    <Slide 
                        direction="up" 
                        in={props.finalizeLoading || props.finalizeFetching}
                    >
                        <Box
                            sx={{ 
                                borderRadius:8,
                                background:"#fff",
                                width:1,
                                height:65,
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:'center',
                                px:3,
                                position:'relative',
                                zIndex:1,
                                // left:"50%",
                                // transform:"translateX(-50%)",
                                overflow:'visible',
                                "&:before" : {
                                    position:'absolute',
                                    content:"''",
                                    left:6,
                                    right:6,
                                    top:6,
                                    bottom:6,
                                    zIndex:-1,
                                    borderRadius:12,
                                    filter:"blur(12px)",
                                    background: 'linear-gradient(var(--gradient-angle), #31415f, #0D61FF, #C2D7FF)',
                                    animation: 'rotation 5s linear infinite'
                                },
                                "&:after" : {
                                    position:'absolute',
                                    content:"''",
                                    left:0,
                                    right:0,
                                    top:0,
                                    bottom:0,
                                    zIndex:-1,
                                    borderRadius:12,
                                    background:'#fff'
                                }
                            }}
                        >
                            <Box width={60} />
                            <Subheading>
                                {t('echeck_processing_order')}
                            </Subheading>
                            <Truck/>
                        </Box>
                    </Slide>
                </Box>
            </Box>

        </Box>

    );
}

export default EcheckView;