import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import { useCreateOrderMutation } from "../../../../newapi/order/ordersSlice";
import Details from "./views/Details";
import AddressDetails from "./views/AddressDetails";
import Orderlines from "./views/Orderlines";

const MpAddOrder = (props) => {
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useCreateOrderMutation} isModal title={t("add_order")}>
                <Details/>
                <AddressDetails/>
                <Orderlines/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddOrder;