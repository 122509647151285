// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { yupRequired, yupNumberRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import { countriesFromLocale } from '../../../../../utils/countries';
import { countryCodeNL } from '../../../../../utils/constants';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);

    useEffect(() => {
        if(!data.shippingAddress?.country_code) {
            handleInput('shippingAddress.country_code', countryCodeNL);
        }
    }, []);
    
    const handleInput = (key, val) => {
        if (key.indexOf('.') > -1) {
            key = key.split('.')[1];

            const shippingAddress = data.shippingAddress ? { ...data.shippingAddress } : {};
            shippingAddress[key] = val;
            props.handleDataUpdate({ shippingAddress: shippingAddress });
            dispatch(setTabErrors({ ...props.errors, [key]: '' }));
        } else {
            props.handleDataUpdate({ [key]: val });
            dispatch(setTabErrors({ ...props.errors, [key]: '' }));
        }
    };

    // Set virtual product is true for POST request
    useEffect(() => {
        if(loaded) {
            props.handleDataUpdate({ isVirtual: true });
        }
        setLoaded(true);
    }, [loaded]);

    return (
        <>
            <Box 
                sx={{
                    display:'flex',
                    flexDirection:"column",
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("first_name")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.firstName"
                            value={data.shippingAddress ? data.shippingAddress.firstName : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.firstName`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("last_name")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.lastName"
                            value={data.shippingAddress ? data.shippingAddress.lastName : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.lastName`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("company")} ({t("_optional")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.company"
                            value={data.shippingAddress ? data.shippingAddress.company : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.company`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("phone")} ({t("_optional")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.phone"
                            value={data.shippingAddress ? data.shippingAddress.phone : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.phone`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("email")} ({t("_optional")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="email"
                            value={data.email ?? ''}
                            onChange={handleInput}
                            error={props.errors.email}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("address1")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.address1"
                            value={data.shippingAddress ? data.shippingAddress.address1 : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.address1`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("address2")} ({t("_optional")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.address2"
                            value={data.shippingAddress ? data.shippingAddress.address2 : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.address2`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("postal")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.postal"
                            value={data.shippingAddress ? data.shippingAddress.postal : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.postal`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("city")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                        <ShValidatedInput
                            name="shippingAddress.city"
                            value={data.shippingAddress ? data.shippingAddress.city : ''}
                            onChange={handleInput}
                            error={props.errors[`shippingAddress.city`]}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("country")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                        }}
                    >
                    <ShValidatedInputDropdown
                            name="shippingAddress.country_code"
                            displayName="name"
                            changeField="alpha2"
                            options={countries ?? null}
                            noSelection={t("choose_option")} 
                            onChange={handleInput}
                            selected={countries?.findIndex(country => (data.shippingAddress?.country_code && data.shippingAddress.country_code === country.alpha2)) ?? -1}
                            error={props.errors[`shippingAddress.country_code`]}
                            closeOnSelection
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search_relation_placeholder")
                            }}
                            styles={{
                                minWidth:300
                            }}

                            disallowDeselection
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const AddressDetails = (props) => {
    const {t} = useTranslation();

    const addressFields = [
        'address1',
        'postal',
        'city',
        'country_code',
        'firstName',
        'lastName'
    ];
    
    const conditionalSchema = [
        ...addressFields.map(field => ({
            key: `shippingAddress.${field}`,  // Use shippingAddress prefix
            errorField: t(field),
            alwaysRequired: false,
            condition: (data) => true,
            type: "text"
        }))
    ];

    return (
        <ProgressionTab 
            tabId="tabAddressDetails" 
            subtitle={t("address_details")} 
            step={2}
            nextTab={"tabOrderlines"}
            maxSteps={3}
            conditionalSchema={conditionalSchema}
        >
            <Content/>
        </ProgressionTab>
    )
}

export default AddressDetails;