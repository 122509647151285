import { Box, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material";
import Text from "../../../../theme/text/Text";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useTranslation } from "react-i18next";
import { useCancelShipmentMutation, useGetOrderShipmentsQuery, useGetShipmentLabelMutationMutation, useGetShipmentLabelQuery } from "../../../../../newapi/order/ordersSlice";
import { tokens } from "../../../../../theme";
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as ParcelLogo } from '../../../../../styles/svg/parcel.svg';
import { ReactComponent as CirroLogo } from '../../../../../styles/svg/cirro.svg';
import CloseIcon from '@mui/icons-material/Close';
import cx from 'classnames';
import Shbutton from "../../../../theme/buttons/Shbutton";
import { Download, PictureAsPdf } from "@mui/icons-material";
import { getShipperColorSettingsByType } from "../../../../Streams/StreamShipperSettings";
import Subheading from "../../../../theme/text/Subheading";
import TopRight from "../../../../theme/positionals/TopRight";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import ShGridEmpty from "../../../../theme/datagrid2/Status/ShGridEmpty";
import { showToastMessage } from "../../../../../utils/toasts";
import { format } from "date-fns";
import { general_states } from "../../../../../utils/staticEnums";
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const ShipperLogoMap = {
    postnl: PostNLLogo,
    dhl: DhlLogo,
    dpd: DpdLogo,
    bol: BolLogo,
    parcel: ParcelLogo,
    cirro: CirroLogo,
};

const getShipperColorSettings = (shipperType) => {
    switch (shipperType) {
        case 'postnl':
            return { background: '#FFE9D6' };
        case 'dhl':
            return { background: '#FFF8F2' };
        case 'dpd':
            return { background: '#E0F7FA' };
        case 'bol':
            return { background: '#E8EAF6' };
        case 'cirro':
            return { background: '#4646f0' };
        default:
            return { background: '#FFFFFF' };
    }
};

const Shipments = (props) => {
    const theme = useTheme();
    const { t,i18n } = useTranslation();
    const colors = tokens(theme.palette.mode);
    const [getLabelId, setGetLabelId] = useState(null);
    // const [shipment, setShipment] = useState(null);

    // const setGetLabel = (shipment) => {
    //     setShipment(shipment);
    //     setGetLabelId(shipment?.id);
    // }

    const { data: shipmentsData, isLoading: shipmentsIsLoading, isFetching: shipmentsIsFetching, isError: shipmentsIsError,refetch:refetchShipments } = useGetOrderShipmentsQuery(props.data.id, {
        skip: !props.data.id
    });


    const [getShipmentLabel, { data: shipmentLabelData, isLoading: shipmentLabelIsLoading, isError: shipmentLabelIsError }] = useGetShipmentLabelMutationMutation();
    const [cancelShipment, { data: cancelShipmentData, isLoading: cancelShipmentLoading, isError: cancelShipmentError }] = useCancelShipmentMutation();
   
    const [shipmentToDelete,setShipmentToDelete] = useState(null);
    const [open, setOpen] = useState(false);
    const confirmDelete = async ()  => {
        await cancelShipment(shipmentToDelete.id).unwrap().then(res => {
            refetchShipments();
            // props.setSelectedOrder((prevState) => {
            //     // Create a shallow copy of the shipments array
            //     const updatedShipments = [...prevState.shipments];
        
            //     const shipmentIndex = updatedShipments.findIndex(locShipment => locShipment.id === shipmentToDelete.id);
        
            //     if (shipmentIndex !== -1) {
            //         // Update the state of the found shipment to 'cancelled'
            //         updatedShipments[shipmentIndex] = {
            //             ...updatedShipments[shipmentIndex], // Keep other properties the same
            //             state: general_states.CANCELLED // Update the status to cancelled
            //         };
            //     }
        
            //     // Return the updated state with the modified shipments array
            //     return { ...prevState, shipments: updatedShipments };
            // });
            // console.log(res);
        }).catch(err => {
            console.error(err);
        })
     }
    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

 

    const renderShipperLogo = (shipperType) => {
        const LogoComponent = ShipperLogoMap[shipperType];
        return LogoComponent ? <LogoComponent className={shipperType} /> : null;
    };

    const getCirroLink = (shipment) => {
        if(shipment.externalShipperCode === "DHL") {
            return `https://my.dhlecommerce.nl/home/tracktrace/${shipment.trackingNumber}/${shipment.postal}`;
        }
        if(shipment.externalShipperCode === "POSTNL") {
            return `https://jouw.postnl.nl/track-and-trace/${shipment.trackingNumber}-${shipment.country}-${shipment.postal}`;
        }
        return `https://www.cirrotrack.com/parcelTracking?id=${shipment.externalShipperId}`
    }

    const getTrackingLink = (shipment) => {
        // eslint-disable-next-line default-case
        switch(shipment.shipper?.type) {
            case "dhl":
                return `https://my.dhlecommerce.nl/home/tracktrace/${shipment.trackingNumber}/${shipment.postal}`;
            case "postnl": 
                return `https://jouw.postnl.nl/track-and-trace/${shipment.trackingNumber}-${shipment.country}-${shipment.postal}`;
            case "dpd": 
                showToastMessage("error", t("tracking_link_not_supported"));
                break;
            case "cirro": 
                return getCirroLink(shipment);
            case "sendcloud":
                return `https://tracking.eu-central-1-0.sendcloud.sc/forward?carrier=${shipment.externalShipperName}&code=${shipment.trackingNumber}&destination=${shipment.country}&lang=en-us&source=NL&type=parcel&verification=${shipment.postal}`;
            default:
                showToastMessage("error", `${t("tracking_link_not_supported")}: ${shipment.shipper?.type}`);
        }
    }

    return (
        <Box
            sx={{
                flexDirection:"column",
                padding:2,
                flex:1,
                width:1,
                // width: 'calc(50% - 12px)', // Subtract the gap from the total width
                display: 'flex',
                "@media screen and (max-width: 47.9375em)" : {
                    gap:1.5,
                    position:"relative",
                }
            }}
        >
            {shipmentsIsLoading && <ShTextSkeleton />}
            {shipmentsIsError && <Text color="error">{t('error.loading')}</Text>}
            {shipmentsData && [...shipmentsData] // Create a shallow copy of the array
                            .sort((a, b) => new Date(b.shipmentDate) - new Date(a.shipmentDate)) // Sort by shipmentDate, descending
                            .map((shipment, index) => {
                const colorSettings = getShipperColorSettingsByType(shipment?.shipper?.type);

                return (
                    <Box
                        key={"shipment-list"+index}
                        sx={{
                            pb:3,
                            ml:3.5,
                            position:'relative',
                            "&:before" : {
                                zIndex:2,
                                content:'""',
                                display:'block',
                                width:6,
                                height:6,
                                background: theme.palette.mode === "dark" ? colors.txt["softenedMore"] : colors.grey[400],
                                position:'absolute',
                                top:10,
                                left:-24,
                                borderRadius:1,
                            },
                            "&:after" : {
                                zIndex:1,
                                content:'""',
                                display:'block',
                                width:2,
                                height:1,
                                background: theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100],
                                position:'absolute',
                                top:10,
                                left:-22,
                                borderRadius:1,
                            }
                        }}

                    >
                        <Box
                            sx={{
                                display:"flex",
                                // justifyContent:'space-between',
                            }}
                        >
                            <Text bold>

                                {new Date(shipment?.shipmentDate).toLocaleString(i18n.language, {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                })}
                            </Text>&nbsp;
                            <Text italic>
                                {new Date(shipment?.shipmentDate).toLocaleString(i18n.language, {
                                    day: '2-digit',
                                    month: 'long'
                                })}

                            </Text>
                        </Box>
                        <Box
                            className={cx(``, {
                                'cancelled': shipment.state === general_states.CANCELLED,
                            })}
                            sx={{
                                // background: "#FFF8F2",
                                background:colorSettings ? colorSettings.background : colors.grey[100],
                                border:`1px solid ${colorSettings ? colorSettings.streamrulehover : colors.grey[200]}`,
                                borderRadius:3,
                                padding:2,
                                width:1,
                                // overflow:"hidden",
                
                                "& .postnl" : {
                                    width:24,
                                    height:24,
                                },
                
                                "& .dpd" : {
                                    width:24,
                                    height:24,
                                },
                                "& .dhl" : {
                                    width:24,
                                    height:24,
                                },
                                "& .bol" : {
                                    width:32,
                                    // width:24,
                                    height:16,
                                },
                                "& .cirro" : {
                                    width:24,
                                    height:24,
                                },
                
                                
                                "@media screen and (max-width: 47.9375em)" : {
                                    pl:0,
                                    pr:0,
                                    pb:0,              
                                    position:"relative"              
                                },
                                "&.cancelled" : {
                                    background: colors.red[100],
                                    border:`1px solid ${colors.red[200]}`,
                                }
                            }}
                        >
                        {/* Header */}
                            <Box
                                sx={{
                                    display:"flex",
                                    justifyContent:"space-between",
                                    alignItems:"flex-start"
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:2,
                                        alignItems:"center",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            alignItems:"flex-start",
                                            px:2,
                                        }
                                    }}
                                >
                                    {shipment.shipper &&
                                        <Box
                                            sx={{
                                                width:32,
                                                height:32,
                                                display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    // width:"unset",
                                                    // height:"unset"
                                                }
                                            }}
                                        >
                                            {renderShipperLogo(shipment?.shipper?.type)}
                                        
                                        </Box>
                                    }
                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                display:"none",
                                            }
                                        }}
                                    >
                                        <Subheading color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.shipper.name}{shipment?.shipper.type === 'cirro' && ` (${shipment?.externalShipperName})`}</Subheading>
                                    </Box>

                                    {/* Mobile  */}
                                    <Box
                                        sx={{
                                            display:"none",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                display:"flex",
                                                flexDirection:"column"
                                            }
                                        }}
                                    >
                                        <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.shipper.name}{shipment?.shipper.type === 'cirro' && ` (${shipment?.externalShipperName})`}</Text>
                                        <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null}  bold>
                                            {shipment?.trackingNumber}
                                            {/* {new Date(shipment?.shipmentDate).toLocaleString(i18n.language, { dateStyle: 'short', timeStyle: 'short' })} */}
                                        </Text>
                                    </Box>

                                </Box>

                                {/* Hide button on mobile */}
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:2,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            display:"none",
                                        }
                                    }}
                                >

                                    <ButtonBase 
                                            
                                        sx={{
                                        }}
                                    >
                                        <Box
                                            className={cx('',{
                                                'cancelled': shipment.state === general_states.CANCELLED,
                                            })}
                                            onClick={async () => {
                                                await getShipmentLabel(shipment.id).unwrap().then(res => {
                                                    res.labels?.forEach(label => {
                                                        const decodedData = atob(label);
                                                        const arrayBuffer = new ArrayBuffer(decodedData?.length);
                                                        const uint8Array = new Uint8Array(arrayBuffer);
                                                        for (let i = 0; i < decodedData.length; i++) {
                                                            uint8Array[i] = decodedData.charCodeAt(i);
                                                        }
                                                        const blob = new Blob([uint8Array], { type: 'application/pdf' });
                                                        const pdfUrl = URL.createObjectURL(blob);
                                                        window.open(pdfUrl, '_blank');
                                                    });
                                                }).catch(err => {
                                                    // console.error(err);
                                                })
                                            }}
                                            sx={{
                                                    background: colorSettings ? colorSettings.base : colors.grey[400],
                                                    // border: `1px solid ${colorSettings ? colorSettings.base : colors.grey[400]}`,
                                                    background: colorSettings ? colorSettings.streamrule : colors.grey[400],
                                                    color: colorSettings ? colorSettings.base : colors.grey[400],

                                                    display:"flex",
                                                    px:3,
                                                    height:40,
                                                    borderRadius:5,
                                                    gap:3,
                                                    // color:"#fff",
                                                    fontSize:"14px",
                                                    fontFamily:"Roboto, sans-serif",
                                                    fontWeight:500,
                                                    alignItems:"center",
                                                    "&.cancelled" : {
                                                        background:colors.red[400],
                                                        border:`2px solid ${colors.red[400]}`,
                                                        color:theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["white"],
                                                    }
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    ml:"auto"
                                                }}
                                            >
                                            {t("download_label")}
                                            </Box>
                                            <Box
                                                sx={{
                                                    ml:"auto",
                                                    display:"flex",
                                                    alignItems:"center",
                                                }}
                                            >
                                            <Download/>
                                            </Box>
                                        </Box>
                                    </ButtonBase>
                                    {shipment.state !== general_states.CANCELLED && shipment.synced &&
                                        <ButtonBase
                                            sx={{
                                            }}
                                        >
                                            <Box

                                                onClick={() =>{
                                                    const link = getTrackingLink(shipment);
                                                    window.open(link, "_blank");
                                                }}
                                                sx={{
                                                        background: colorSettings ? colorSettings.base : colors.grey[400],
                                                        color: "#fff",

                                                        display:"flex",
                                                        px:3,
                                                        height:40,
                                                        borderRadius:5,
                                                        gap:3,
                                                        fontSize:"14px",
                                                        fontFamily:"Roboto, sans-serif",
                                                        fontWeight:500,
                                                        alignItems:"center",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        ml:"auto"
                                                    }}
                                                >
                                                {t("tracking_link")}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        ml:"auto",
                                                        display:"flex",
                                                        alignItems:"center",
                                                    }}
                                                >
                                                <Download/>
                                                </Box>
                                            </Box>
                                        </ButtonBase>
                                    }
                                     {!shipment.synced && shipment.state !== general_states.CANCELLED &&
                                        <ButtonBase
                                            onClick={async () => {
                                                setShipmentToDelete(shipment);
                                                confirmDeleteModal.setOpen(true);
                                            }}
                                            key={index}
                                            sx={{
                                                display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                width:40,
                                                height:40,
                                                borderRadius:5,
                                                background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[400],
                                                color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"],
                                                fontWeight:"bold"
                                            }}
                                        >
                                            <CloseIcon fontSize="small"/>
                                        </ButtonBase>
                                    }
                                    {/* <Shbutton variant="contained">Download</Shbutton> */}
                                </Box>

                                <Box
                                    sx={{
                                        display:"none",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            display:"unset",
                                        }

                                    }}
                                >

                                    <TopRight>
                                        {!shipment.synced && shipment.state !== general_states.CANCELLED &&
                                            <ButtonBase
                                                onClick={async () => {
                                                    setShipmentToDelete(shipment);
                                                    confirmDeleteModal.setOpen(true);
                                                }}
                                                key={index}
                                                sx={{
                                                    mr:2,
                                                    mt:2,
                                                    display:"flex",
                                                    alignItems:"center",
                                                    justifyContent:"center",
                                                    width:32,
                                                    height:32,
                                                    borderRadius:5,
                                                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[400],
                                                    color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"],
                                                    fontWeight:"bold"
                                                }}
                                            >
                                                <CloseIcon fontSize="small"/>
                                            </ButtonBase>
                                        }
                                        {shipment.state === general_states.CANCELLED && 
                                            <Box
                                                className={cx('',{
                                                    'cancelled': shipment.state === general_states.CANCELLED,
                                                })}
                                                sx={{
                                                    height:32,
                                                    px:2,
                                                    background:colorSettings ? colorSettings.streamrule : colors.grey[200],
                                                    borderTopLeftRadius:6,
                                                    borderTopRightRadius:12,
                                                    borderBottomLeftRadius:12,
                                                    borderBottomRightRadius:6,
                                                    lineHeight:"32px",
                                                    fontFamily:"Roboto, sans-serif",
                                                    fontSize:"14px",
                                                    color: colorSettings ? colorSettings.base : colors.grey[100],
                                                    fontWeight:500,
                                                    "&.cancelled" : {
                                                        background:colors.red[100],
                                                        color:colors.red[400],
                                                    }
                                                }}
                                            >
                                                {t("cancelled")}
                                            </Box>
                                        }

                                    </TopRight>
                                </Box>

                            </Box>

                            <Box 
                                sx={{
                                    pt:1,
                                    display:"flex",
                                    gap:8,
                                    pl:6,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        px:2,
                                        pt:2,
                                        gap:4,
                                        pl:3,
                                    }
                                }}
                            >
                                <Box 
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column", 
                                        "@media screen and (max-width: 47.9375em)" : {
                                            display:"none"
                                        }
                                    }}
                                >
                                    <Box sx={{display:"flex",flexDirection:"column"}}>
                                        <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,.55)" : null}  >{t("shipped_date")}</Text>
                                        <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null}  bold>
                                            {/* {new Date(shipment?.shipmentDate).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })} */}
                                            {new Date(shipment?.shipmentDate).toLocaleString(i18n.language, { dateStyle: 'short', timeStyle: 'short' })}
                                        </Text>
                                    </Box>
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.trackingNumber}</Text>
                                </Box>

                                <Box 
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            pl:5,
                                        }
                                    }}
                                >
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,.55)" : null} light>{t('address')}</Text>
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null}  bold>{shipment?.street} {shipment?.house_nr}{shipment?.house_nr_extra ? ` ${shipment?.house_nr_extra}` : ''}</Text>
                                    <Text color={theme.palette.mode === "dark"? "rgba(49,65,95,1)" : null} >{shipment?.postal} {shipment?.city}, {shipment?.country}</Text>
                                </Box>
                                

                            </Box> 
                            <Box
                                sx={{
                                    display:"none",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        mt:3,
                                        display:"flex",
                                        width:1,
                                    }
                                }}
                            >
                                
                                <ButtonBase
                                    sx={{
                                        flex:1,
                                        display:'block',
                                    }}
                                >
                                    <Box
                                        className={cx('',{
                                            'cancelled': shipment.state === general_states.CANCELLED,
                                        })}

                                        onClick={async () => {
                                            await getShipmentLabel(shipment.id).unwrap().then(res => {
                                                res.labels?.forEach(label => {
                                                    const decodedData = atob(label);
                                                    const arrayBuffer = new ArrayBuffer(decodedData?.length);
                                                    const uint8Array = new Uint8Array(arrayBuffer);
                                                    for (let i = 0; i < decodedData.length; i++) {
                                                        uint8Array[i] = decodedData.charCodeAt(i);
                                                    }
                                                    const blob = new Blob([uint8Array], { type: 'application/pdf' });
                                                    const pdfUrl = URL.createObjectURL(blob);
                                                    window.open(pdfUrl, '_blank');
                                                });
                                            }).catch(err => {
                                                // console.error(err);
                                            })
                                        }}
                                        sx={{
                                            display:"none",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background: colorSettings ? colorSettings.base : colors.grey[400],
                                                // border: `1px solid ${colorSettings ? colorSettings.base : colors.grey[400]}`,
                                                background: colorSettings ? colorSettings.streamrule : colors.grey[400],
                                                color: colorSettings ? colorSettings.base : colors.grey[400],

                                                display:"flex",
                                                px:3,
                                                height:40,
                                                borderBottomLeftRadius:12,
                                                borderBottomRightRadius:!(shipment.state !== general_states.CANCELLED && shipment.synced) ? 12 :0,
                                                
                                                fontSize:"14px",
                                                fontFamily:"Roboto, sans-serif",
                                                fontWeight:500,
                                                alignItems:"center",
                                                "&.cancelled" : {
                                                    background:colors.red[400],
                                                    border:`2px solid ${colors.red[400]}`,
                                                    color:theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["white"],
                                                }
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                ml:"auto"
                                            }}
                                        >
                                            {t("download_label")}
                                        </Box>
                                        <Box
                                            sx={{
                                                ml:"auto",
                                                display:"flex",
                                                alignItems:"center",
                                            }}
                                        >
                                        <Download/>
                                        </Box>
                                    </Box>
                                </ButtonBase>

                                {shipment.state !== general_states.CANCELLED && shipment.synced &&
                                    <ButtonBase
                                        sx={{
                                            flex:1,
                                            display:'block',
                                        }}
                                    >
                                        <Box

                                            onClick={() =>{
                                                const link = getTrackingLink(shipment);
                                                window.open(link, "_blank");
                                            }}
                                            sx={{
                                                display:"none",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    background: colorSettings ? colorSettings.base : colors.grey[400],
                                                    color: "#fff",
                                                    display:"flex",
                                                    px:3,
                                                    height:40,
                                                    borderBottomRightRadius:12,
                                                    fontSize:"14px",
                                                    fontFamily:"Roboto, sans-serif",
                                                    fontWeight:500,
                                                    alignItems:"center",
                                                }
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    ml:"auto"
                                                }}
                                            >
                                            {t("tracking_link")}
                                            </Box>
                                            <Box
                                                sx={{
                                                    ml:"auto",
                                                    display:"flex",
                                                    alignItems:"center",
                                                }}
                                            >
                                            <Download/>
                                            </Box>
                                        </Box>
                                    </ButtonBase>
                                }
                            </Box>
                        </Box>
                    </Box>
                );
            })}
            {shipmentsData?.length === 0 && !shipmentsIsLoading && !shipmentsIsFetching &&
                <ShGridEmpty/> 
            }
            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                // data={confirmDeleteModal.data}
                data={shipmentToDelete?.trackingNumber}
                confirm={confirmDeleteModal.confirm}
                title={t("cancel_shipment")}
                content={t("are_you_sure_you_want_to_cancel_shipment")}
                confirmBtn={t("cancel")}
            />
        </Box>
         
    );
}

export default Shipments;
