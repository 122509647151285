
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const ECHECK_BASE = "/echeck";
const buildUrl = (endpoint) => ECHECK_BASE + endpoint;


const echeckApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        StartFindOrders: builder.query({
            query: id => ({
                url: buildUrl(`/startfindorders/${id}`),
                method: 'GET'
            }),
            keepUnusedDataFor:0,
            //providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        StartFindOrdersMutation: builder.mutation({
            query: (id) => ({
              url: buildUrl(`/startfindorders/${id}`),
              method: 'GET',  
            }),
        }),
        findPickingJobs: builder.query({
            query: (body) => ({
              url: buildUrl(`/findpickingjobs`),
              method: 'POST', 
              body
            }),
        }),
        findProductInPickingJob: builder.mutation({
            query: (body) => ({
              url: buildUrl(`/findproductinpickingjob`),
              method: 'POST', 
              body,
            }),
        }),
        findLocationInPickingJob: builder.mutation({
            query: (body) => ({
              url: buildUrl(`/findlocationinpickingjob`),
              method: 'POST', 
              body,
            }),
        }),
        findPickingJob: builder.mutation({
            query: (body) => ({
              url: buildUrl(`/pickingjob/${body.id}`),
              method: 'POST', 
              body
            }),
        }),
        pickingJob: builder.query({
            query: (id) => ({
              url: buildUrl(`/pickingjob/${id}`),
              method: 'GET', 
            }),
        }),
        finalizeOrder: builder.mutation({
            query: (body) => ({
                url: buildUrl(`/finalizeorder?_=${Date.now()}`),
                method: 'POST',
                body: body
            }),
            //providesTags: (result, error, body) => [{ type: `${body.order.id}Single` }]
        }),
        setJoker: builder.mutation({
            query: id => ({
                url: buildUrl(`/joker/${id}`),
                method: 'GET'
            }),
            //providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        getDeliveryOptions: builder.query({
            query: (body) => ({
                url: buildUrl(`/deliveryoptions`),
                method: 'POST',
                body: body
            }),
        }),
        addShipment: builder.mutation({
            query: (body) => ({
                url: buildUrl(`/addshipment`),
                method: 'POST',
                body: body
            }),
        })
    })
});

export const {
    useStartFindOrdersMutationMutation,
    useStartFindOrdersQuery,
    useFindProductInPickingJobMutation,
    useFindLocationInPickingJobMutation,
    useFinalizeOrderMutation,
    useSetJokerMutation,
    useGetDeliveryOptionsQuery,
    useAddShipmentMutation,
    useGetPickingJobQuery,
    useFindPickingJobMutation,
    useFindPickingJobsQuery,
    useFindProductInPickingJobQuery,
  } = echeckApiSlice;
