import { Box, useTheme } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";
// import SPStockDetails from "../../SpStockDetails/SPStockDetails";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
// import SpLocationDetails from "../../SpLocationDetails/SpLocationDetails";
import { useGetOrderPickingLinesQuery, useGetOrderPickingListPDFMutation, useUpdateOrderPickingLineMutation } from '../../../../../newapi/order/ordersSlice';

import PrintIcon from '@mui/icons-material/Print';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import { showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import WarehousePickingLinesCard from "../../../../global/cards/orders/PickingLinesCard";
import Shbutton from "../../../../theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import SidepanelLoader from "../../../../theme/sidepanel2/SidepanelLoader";

const SpStockDetails = SidepanelLoader(() => import("../../SpStockDetails/SPStockDetails"));
const SpLocationDetails = SidepanelLoader(() => import("../../SpLocationDetails/SpLocationDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [GetOrderPickingListPDF] = useGetOrderPickingListPDFMutation();
    const [isFetchingPdf, setIsFetchingPdf] = useState(false);

    const handleButtonClick = async () => {
        setIsFetchingPdf(true);
        try {
            const response = await GetOrderPickingListPDF({orders: [props.data.id]}).unwrap();
            const newWindow = window.open(response.data, '_blank', 'noopener,noreferrer');
            if (newWindow) {
                setTimeout(() => {
                    window.URL.revokeObjectURL(response.data);
                }, 500);
            } else {
                window.URL.revokeObjectURL(response.data);
            }
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        } finally {
            setIsFetchingPdf(false);
        }
    };
    
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
                <Shbutton
                        className={"group-btn"}
                        onClick={handleButtonClick}
                        color={theme.palette.mode === "dark" ? "blue" : "primary"}
                        variant="contained"
                        endIcon={<PrintIcon />}
                        disabled={isFetchingPdf}
                    >
                    {isFetchingPdf ? t('loading') : t('print')}
                </Shbutton>
            </ButtonGroup>
        </Box>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    

};

const PickingLines = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [spStockLoading, setSpStockLoading] = useState();

    const [locationIsExtended, setLocationIsExtended] = useState(false);
    const [spLocationLoading, setSpLocationLoading] = useState();

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions data={props.data} />,
            gridOptions:gridOptions
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.data]);
    


    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.stock?.warehouseLocation?.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            if(!spLocationLoading){
                setSpLocationLoading(true);
            }
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        
            setLocationIsExtended(true);
    };

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.stock?.product?.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            if(!spStockLoading){
                setSpStockLoading(true);
            }
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
            setIsExtended(true);
    };

    const [EditPickingLine, {pickingLineIsLoading}] = useUpdateOrderPickingLineMutation();
    const handlePickingLineLocationUpdate = async (params, stockObj) => {
        try {
            const response = await EditPickingLine({id: params.id, orderId: params.orderId, stockId: stockObj.id}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            console.log(err);
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const columns = [
        {
            field: "location", 
            headerName: t('location'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                if (params.row.stock.product.stocks && params.row.stock.product.stocks.length > 1) {
                    return <InputDropdown
                        displayName={"warehouseLocation"} 
                        onChange={(selected) => handlePickingLineLocationUpdate(params.row, params.row.stock.product.stocks[selected])}
                        noSelection={t("select_location")} //Custom text when nothing is selected
                        options={params.row.stock.product.stocks ?? null}
                        selected={params.row.stock.product.stocks?.findIndex(stock => params.row.stock.id === stock.id) ?? -1}
                        closeOnSelection
                        stopPropagation
                    />
                } else {
                    return (
                    <Box onClick={(event) => {
                        event.stopPropagation(); // Prevent click from propagating to the row
                        getLocationDetails(params);
                    }}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.warehouseLocation?.location}</Text>
                    </Box>
                    )
                }
            }
        },
        {
            field: "sku", 
            headerName: t('sku'),
            flex:1.5, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }
                return (
                    <Box paddingLeft={0} onClick={(event) => {
                        event.stopPropagation();
                        getProductDetails(params);
                        }}>
                        <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.product?.sku}</Text><br/>
                        <Text clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.product?.description}</Text>
                    </Box>
                )
            }
        },
        {
            field: "amount", 
            headerName: t('amount'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.amount
            }
        },
        {
            field: "phoneView", 
            headerName: "phoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehousePickingLinesCard 
                        onClick={() => getProductDetails(params.row)} 
                        skeleton={params.row.skeleton ?? false} params={params} 
                        handlePickingLineLocationUpdate={handlePickingLineLocationUpdate}
                        getLocationDetails={getLocationDetails}
                    />
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                // gridActions={<GridActions></GridActions>} 
                content={useGetOrderPickingLinesQuery}
                itemId={props.data?.id}
                onRowClick={getLocationDetails}
                title={t('pickinglines')}
                gridActions={<GridActions data={props.data}></GridActions>} 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>

            {spStockLoading && 
                <SpStockDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    productId={productId}
                />
            }
            
            {spLocationLoading && 
                    <SpLocationDetails
                        isExtended={locationIsExtended}
                        setIsExtended={setLocationIsExtended}
                        locationId={locationId}
                    />
            }


        </Box>
    );
}

export default PickingLines;