// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import NumberInput from '../../../../theme/inputs/NumberInput';
import ShDateRangePicker from "../../../../theme/ShDateRangePicker";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { ENV_TYPES } from "../../../Sidebar";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { yupNumberRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useGetRelationsSelectQuery } from '../../../../../newapi/global/relation/relationSlice';
import { useGetWebshopCredentialsSelectQuery } from '../../../../../newapi/webshop/webshopSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const { data: relations, isLoading } = useGetRelationsSelectQuery();

    // Get values to determine if the relation dropdown should be shown
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];
    const selectedId = useSelector((state) => state.settings.environment.selectedId);

    const { data: webshopCredentials, isLoading: isWebshopCredentialsLoading } = useGetWebshopCredentialsSelectQuery(
        isRelation ? selectedId : props.data?.relationId,
        { skip: !isRelation && !props.data?.relationId }
    );
    
    const [webshopCredentialsOptions, setWebshopCredentialsOptions] = useState([]);
    
    useEffect(() => {
        if(webshopCredentials) {
            const mappedWebshopCredentials = webshopCredentials.map(item => ({  
                id: item.id,
                name: item.description
            }));
            setWebshopCredentialsOptions(mappedWebshopCredentials);
        }
    }, [webshopCredentials]);

    useEffect(() => {
        if(webshopCredentialsOptions && webshopCredentialsOptions.length === 1) {
            props.handleDataUpdate({ webshopCredentialsId: webshopCredentialsOptions[0].id });
            dispatch(setTabErrors({ ...props.errors, webshopCredentialsId: '' }));
        }
    }, [webshopCredentialsOptions]);

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val, ...(key === "relationId" && { webshopCredentialsId: null }) });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const select = (date) => {
        const formattedDate = date[0].startDate.toISOString().split('T')[0];
        props.handleDataUpdate({ date_planned: formattedDate });
    }

    // Set virtual product is true for POST request
    useEffect(() => {
        if(loaded) {
            props.handleDataUpdate({ isVirtual: true });
        }
        setLoaded(true);
    }, [loaded]);

    return (
        <>
            <Box 
                sx={{
                    display:'flex',
                    flexDirection:"column",
                }}
            >
                {isFulfilment && !isRelation && (
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        paddingTop: 1.5,
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                    }}
                >
                    
                    <Box
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("relation")}</Text>
                    </Box>
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInputDropdown
                            key={data.relationId}
                            displayName={"name"} 
                            changeField={"id"}
                            valueKey={"id"}
                            closeOnSelection
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search_relation_placeholder")
                            }}
                            styles={{
                                minWidth:300
                            }}

                            disallowDeselection
                            name="relationId"
                            selected={relations?.find(item => item.id === data.relationId)?.id ?? -1}
                            options={relations ? relations : []}
                            noSelection={t("choose_option")} 
                            onChange={handleInput}
                            error={props.errors.relationId}
                        />
                    </Box>
                </Box>
                )}
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        paddingTop: 1.5,
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                    }}
                >
                    
                    <Box
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("sales_channel")}</Text>
                    </Box>
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInputDropdown
                            key={data.webshopCredentialsId}
                            displayName={"name"} 
                            changeField={"id"}
                            valueKey={"id"}
                            closeOnSelection
                            disallowDeselection
                            name="webshopCredentialsId"
                            selected={webshopCredentialsOptions?.find(item => item.id === data.webshopCredentialsId)?.id ?? -1}
                            options={webshopCredentialsOptions ?? []}
                            noSelection={t("choose_option")} 
                            onChange={handleInput}
                            error={props.errors.webshopCredentialsId}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("reference")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            name="webshopreference"
                            value={data.webshopreference}
                            onChange={handleInput}
                            error={props.errors.webshopreference}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("date_planned")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShDateRangePicker 
                            singleSelect={true} 
                            select={select} 
                            size={32}
                        >

                        </ShDateRangePicker>
                    </Box>
                    
                </Box>
            </Box>
        </>
    );
};

const Details = (props) => {
    const {t} = useTranslation();
    const schema = yup.object({
        webshopCredentialsId: yupNumberRequired('webshopcredentials',t)
    });

    return (
        <ProgressionTab 
            tabId="tabDetails" 
            subtitle={t("Details")} 
            step={1}
            nextTab={"tabAddressDetails"}
            maxSteps={3}
            schema={schema}
        >
            <Content/>
        </ProgressionTab>
    )
}

export default Details;

