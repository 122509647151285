
import WarehouseIcon from '@mui/icons-material/Warehouse';


export const relationReturnsConfig = {
    returns: {
        title: 'all_returns', 
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    // design: {
    //     title: 'design',
    //     icon: <WarehouseIcon/>,
    //     isSingleDataGrid: false,
    // },
    // returnReasons: {
    //     title: 'return_reasons',
    //     icon: <WarehouseIcon/>,
    //     isSingleDataGrid: true,
    // }
};
