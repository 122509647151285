import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Box, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../theme";
import Shbutton from "../../../theme/buttons/Shbutton";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import Subheading from "../../../theme/text/Subheading";
import Text from "../../../theme/text/Text";
import { ENV_TYPES } from "../../Sidebar";

// Other imports
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { useGetOrderQuery, useUpdateOrderMutation, useDuplicateOrderMutation } from "../../../../newapi/order/ordersSlice";
import { getOrderStateData } from "../../../../utils/labelColorText";
import { general_states } from "../../../../utils/staticEnums";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import MSplitOrder from "../../Modals/MSplitOrder";
import MUpdateOrderState from "../../Modals/MUpdateOrderState";
import { spOrderDetailsConfig } from "./spOrderDetailsConfig";

// Views imports
import { useSelector } from "react-redux";
import { selectUserPermission } from "../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from "../../../../utils/permissions";
import { showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../utils/toasts";
import ShDateRangePicker from "../../../theme/ShDateRangePicker";
import Jokers from "./Views/Jokers";
import Logs from "./Views/Logs";
import OrderLines from "./Views/OrderLines";
import PickingLines from "./Views/PickingLines";
import Replenish from "./Views/Replenish";
import Shipments from "./Views/Shipments";
import ShipOrder from "./Views/ShipOrder";
import Status from "./Views/Status";
import WriteOffs from "./Views/WriteOffs";
import MpAddReturn from '../../ModalsProgression/MpAddReturn/MpAddReturn';
import MAddShipment from '../../Modals/MAddShipment';
import MConfirmationWithValue from "../../Modals/MConfirmationWithValue";

const SidePanelActions = (props) => {
    const {t} = useTranslation();
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }
    const handleClose =(split, ie) => {
        addModal.setOpen(false);
    }
    
    return (
        <SidepanelHeaderActiongroup>
                <Shbutton isCancel={true} 
                    onClick={() => props.setIsExtended(false)} 
                    color="primary" 
                    variant="text" 
                    startIcon={<ArrowRightAltIcon/>}
                >
                    Cancel
                </Shbutton>
        </SidepanelHeaderActiongroup>
    )
}

const SpOrderDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const { data: shippers } =  ({warehouseId: props.data.warehouseId},
    //     {
    //         skip: !props.data.warehouseId
    //     }
    // );

    const { t, ready, i18n } = useTranslation();
    const hasModifyPrivilege = useSelector((state) =>
        selectUserPermission(state, [UserPrivileges.OUTBOUND_MANAGE,UserPrivileges.RP_ORDER_MANAGE])
    );

    const hasCreateReturnPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RETURN_MANAGE])
    );

    const hasRPCreateReturnPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_RETURN_MANAGE])
    );

    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    const { data: orderData, isLoading,isFetching,isError } = useGetOrderQuery(props.orderId,
        {
            skip: !props.orderId
        });
        
    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ImportExportIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        // { component: <ShRowSkeleton heading={t("total_products")} /> },
        // { component: <ShRowSkeleton heading={t("total_locations")} /> }
    ], [t]);
    
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions orderId={props.orderId} state={orderData?.state} setIsExtended={setIsExtendedCallback}/>,
        styling: {
            header : {
                padding:"0px !important",
            }
        },
    }), [defaultIcon, defaultOptions, setIsExtendedCallback,props.data]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.strategyId,theme.palette.mode])
    

    const getBackgroundColor = useCallback((state,contrast=400) => {
        const {labelcolor,labeltext} = getOrderStateData(state, theme);

        if(labelcolor === "grey") {
            if(theme.palette.mode === "dark") {
                return colors["grey"][300];
            } else {
                return colors["grey"][200];
            }
        }
        return colors[labelcolor][contrast];
    });

    const getColor = useCallback((state,contrast=400) => {
        const {labelcolor,labeltext} = getOrderStateData(state, theme);
        if(labelcolor === "grey") {
            return colors["txt"]["primary"];
        }
        return colors[labelcolor][contrast];
    });
    
    const [duplicateOrder, {isLoading: isDuplicateLoading}] = useDuplicateOrderMutation();
    const confirmDuplicate = async () => {
        try {
            if (isDuplicateLoading) return;

            const response = await duplicateOrder({id: props.orderId}).unwrap();
            showToastMessagePostRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t('duplicate_failed'),t, err);
        }
    };
    
    const [open, setOpen] = useState(false);
    const confirmDuplicateModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDuplicate,
        data: {
            title: t("duplicate_order"),
            content: t("are_you_sure_you_want_to_duplicate_this_order")
        }
    } 
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        addModal.setOpen(false);
    }
    
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const updateModal = {
        open: updateModalOpen,
        setOpen: setUpdateModalOpen
    }

    const handleCloseUpdate = () => {
        updateModal.setOpen(false);
    }


    const [returnModalOpen, setReturnModalOpen] = useState(false);
    const returnModal = {
        open: returnModalOpen,
        setOpen: setReturnModalOpen
    }

    const [shipmentModalOpen, setShipmentModalOpen] = useState(false);
    const shipmentModal = {
        open: shipmentModalOpen,
        setOpen: setShipmentModalOpen
    }

    const handleReturnClose = () => {
        returnModal.setOpen(false);
    }

    const handleShipmentClose = () => {
        shipmentModal.setOpen(false);
    }

    const [editDateModalOpen, setEditDateModalOpen] = useState(false);
    const editDateModal = {
        open: editDateModalOpen,
        setOpen: setEditDateModalOpen
    }

    const handleCloseEditDate = () => {
        editDateModal.setOpen(false);
    }

    useEffect(() => {
        if (!isLoading && !isFetching && orderData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: orderData.webshopreference,
                subTitle: "",
                icon :defaultIcon,
                options : [
                    // {
                    //     heading: t("total_products"),
                    //     content: strategyData.total_products
                    // },
                    // {
                    //     heading: t("total_locations"),
                    //     content: strategyData.total_locations
                    // }
                ],
                styling: {
                    header : {
                        padding:"0px !important",
                    }
                },
                tabColor:getBackgroundColor(orderData?.state),
                actions: <SidePanelActions orderId={props.orderId} state={orderData?.state} setIsExtended={setIsExtendedCallback}/>
            }));
        }
    }, [orderData, isLoading, isFetching, t,theme.palette.mode]);
   
    const datePlanned = useMemo(() => {
        return orderData?.date_planned && orderData.date_planned !== null
          ? new Date(orderData.date_planned).toLocaleDateString(i18n.language)
          : "-";
      }, [orderData?.date_planned, i18n.language]);

    const [updateOrder,{isLoading: isUpdateLoading}] = useUpdateOrderMutation();

    const config = spOrderDetailsConfig(isRelation);

    const saveDate = async (date) => {
        try {
            const data = await updateOrder({
                id:props.orderId,
                date_planned:date,
            }).unwrap()
            showToastMessageUpdateRequest(t, data);

        } catch (err) {
            // if (err.name === 'ValidationError') {
            //     // Create an errors object where keys are field names and values are error messages
            //     const errors = err.inner.reduce((acc, currentError) => ({
            //         ...acc,
            //         [currentError.path]: currentError.message
            //     }), {});
        
            //     // Set the validation errors in the state
            // } else {
            //     // Handle other errors, such as network errors
            //     showToastMessageRequestError(t("create_failed"), t, err);
            // }
        }
    }

    return (
        isRelation ?
        <>
            {/* {ready ?? */}
                <Sidepanel2
                    isCustom
                    skeleton={isLoading || isFetching}
                    tabConfig={config}
                    content={panel} 
                    isExtended={props.isExtended} 
                    setIsExtended={props.setIsExtended} >
                    <>
                        <OrderLines  config={config.orderLines} canReserveOrderLines={props.canReserveOrderLines} data={orderData?.id}/>
                        <ShipOrder config={config.shipOrder} data={orderData}/>
                        <Shipments config={config.shipments}  data={orderData}/>
                    </>
                    <Box
                    sx={{
                        paddingBottom:3,
                        // position:"relative",
                    }}
                >
                    <Box
                        sx={{
                            pr:3,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"flex-end",
                        }}
                    >
                    <Box 
                        onClick={() => updateModal.setOpen(hasModifyPrivilege)}
                        sx={{
                            background:getBackgroundColor(orderData?.state,100),
                            pt:3,
                            px:3,
                            pb:2,
                            display:"flex",
                            alignItems:"center",
                            fontSize:21,
                            fontWeight:"bold",
                            fontFamily:"Roboto, sans-serif",
                            color: getColor(orderData?.state,400),
                            borderBottomRightRadius:24,
                            "@media screen and (max-width: 47.9375em)" : {
                                position:"absolute",
                                top:0,
                            },
                            "@media screen and (max-width: 566px)" : {
                                display:"none"
                            },
                            position:"relative",
                            cursor:"pointer",
                            // "&::before": {
                            //     content: '""',
                            //     position: "absolute",
                            //     left: 0,
                            //     top: 0,
                            //     height: '100%',
                            //     width: '100%',
                            //     borderBottomRightRadius:24,
                            //     background: "transparent",
                            //     zIndex: 1,
                            //     pointerEvents: "none",
                            //     transition: hasModifyPrivilege && ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) ? 
                            //         "background 200ms cubic-bezier(0.4, 0, 0.2, 1), border 200ms cubic-bezier(0.4, 0, 0.2, 1)" : "none",
                            // },
                            "&:hover&::before": {
                                background: hasModifyPrivilege && ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) ?
                                    "rgba(0,0,0, 0.2)" : "transparent"
                            }
                        }}
                    >
                       {t(orderData?.state === general_states.REPLENISH ? general_states.OPEN : orderData?.state)}
                       {orderData && hasModifyPrivilege && ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) &&
                       <EditIcon sx={{marginLeft:1.5}} />
                       }
                    </Box>
                        {orderData && hasModifyPrivilege && ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) &&
                        <Box
                            sx={{
                                pb:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"none"
                                }
                            }}
                        >
                            {hasModifyPrivilege &&
                            <ButtonGroup version={2} isSidepanel>
                                {orderData.amountOfProducts > 1 &&
                                <Shbutton
                                    block
                                    onClick={() => addModal.setOpen(true)}
                                    className={"group-btn"}
                                    color={"primaryGrey"}
                                    variant="contained"
                                >
                                    {t('split_order')}
                                </Shbutton>
                                }
                                <Shbutton
                                    block
                                    onClick={() => confirmDuplicateModal.setOpen(true)}
                                    className={"group-btn"}
                                    color={"primary"}
                                    variant="contained"
                                    endIcon={<ContentCopyIcon />}
                                >
                                    {t('duplicate_order')}
                                </Shbutton>
                            </ButtonGroup>
                            }
                        </Box>
                        }

                        <Box
                            sx={{
                                display:"unset",
                                "@media screen and (min-width: 47.9375em)" : {
                                    display:"none",
                                }
                            }}
                        >
                            {/* {console.log(strategyData?.state)} */}
                            <SidePanelActions orderId={props.orderId} state={orderData?.state} setIsExtended={setIsExtendedCallback} />
                        </Box>

                    </Box>

                    <Box
                        sx={{
                            px:3,
                            pt:4,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column"
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    alignItems:"center",
                                    flexDirection:"column",
                                    mb:3,
                                }
                            }}
                        >
                            <Box
                                sx={{

                                    display:"flex",
                                    alignItems:"center",
                                    gap:2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:1,
                                    }}
                                >
                                    <Subheading> {t("order")} {orderData?.webshopreference}</Subheading>
                                </Box>

                            </Box>
                            <Box>
                                <Text light>{orderData?.webshopcredentials?.description}</Text>
                            </Box>
                        </Box>
{/* <Subheading */}
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    gap:2,
                                    // flexDirection:"column"
                                },
                                "@media screen and (max-width: 566px)" : {
                                    width:1,
                                    flexDirection:"column"
                                }
                            }}
                        >

                            <Box
                                sx={{
                                    // height:40,
                                    lineHeight:"40px",
                                    background:getBackgroundColor(orderData?.state,100),
                                    px:3,
                                    fontSize:14,
                                    fontWeight:"bold",
                                    fontFamily:"Roboto, sans-serif",
                                    color: getColor(orderData?.state),
                                    borderRadius:20,
                                    display:"none",
                                    alignItems:"center",
                                    flex:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"flex",
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        justifyContent:"center"
                                    }
                                }}
                            >
                                {t(orderData?.state)}
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:4,
                                    borderRadius:7,
                                    height:56,
                                    px:4,
                                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                                    "@media screen and (max-width: 47.9375em)" : {
                                        // width:400,
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        flexDirection:"column",
                                        gap:2,
                                        height:"unset",
                                        px:3,
                                        py:3,
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("delivery_name")}</Text>
                                    <Text>{orderData?.deliveryName ?? t("unknown")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    
                                    <Text sx={{flexShrink:0}} light noBreak>{t("date_planned_shipment")}</Text>
                                    {/* {general_states} */}
                                    {hasModifyPrivilege && ![general_states.SHIPPED, general_states.PICKING_IN_PROGRESS, general_states.FULLYPICKED, general_states.CANCELLED].includes(orderData?.state) ? 
                                        <Box
                                            sx={{
                                                width:1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width:1,
                                                    display:"unset",
                                                    "@media screen and (max-width: 566px)" : {
                                                        display:"none"
                                                    }

                                                }}
                                            >
                                                <ShDateRangePicker
                                                    explicit={true}
                                                    singleSelect={true}
                                                    startDate={orderData?.date_planned}
                                                    select={(value) => saveDate(value[0].startDate)}
                                                    size={20}
                                                >
                                                </ShDateRangePicker>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width:1,
                                                    display:"none",
                                                    "@media screen and (max-width: 566px)" : {
                                                        display:"unset"
                                                    }

                                                }}
                                            >
                                                <ShDateRangePicker
                                                    explicit={true}
                                                    singleSelect={true}
                                                    startDate={orderData?.date_planned}
                                                    select={(value) => saveDate(value[0].startDate)}
                                                    size={32}
                                                >
                                                </ShDateRangePicker>
                                            </Box>
                                        </Box>
                                    : 
                                        <Text>{datePlanned}</Text>
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("orderlines_count")}</Text>
                                    <Text>{orderData?.amountOfLines}</Text>
                                </Box>

                                
                            </Box>
                            
                        </Box>

                    </Box>
                </Box>
                </Sidepanel2>
            <MSplitOrder orderId={props.orderId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            <MUpdateOrderState orderId={props.orderId} state={orderData?.state} open={updateModal.open} onOpen={() => updateModal.setOpen(true)} onClose={() => updateModal.setOpen(false)} handleClose={handleCloseUpdate}/>
            {/* } */}
            
            <MConfirmationWithValue
                open={confirmDuplicateModal.open}
                handleClose={() => confirmDuplicateModal.setOpen(false)}
                data={confirmDuplicateModal.data}
                confirm={confirmDuplicateModal.confirm}
            />
        </>
        :
        <>
            <Sidepanel2
                isCustom
                skeleton={isLoading || isFetching}
                tabConfig={config}
                content={panel} 
                isExtended={props.isExtended} 
                setIsExtended={props.setIsExtended} >
                <>
                    <OrderLines config={config.orderLines}  data={props.orderId}/>
                    <PickingLines config={config.pickingLines} data={orderData} />
                    <Replenish config={config.replenish}  data={orderData}/> 
                    <ShipOrder config={config.shipOrder}  data={orderData}/>
                    <WriteOffs config={config.writeOffs} data={orderData}/>
                    <Shipments config={config.shipments}  data={orderData}/>
                    <Jokers config={config.jokers} actions data={orderData}/>
                    <Logs config={config.logs} data={orderData}/>
                </>
                    <Box
                    sx={{
                        paddingBottom:3,
                        // position:"relative",
                    }}
                >
                    <Box
                        sx={{
                            pr:3,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"flex-end",
                        }}
                    >
                        <Box 
                            onClick={() => updateModal.setOpen(hasModifyPrivilege)}
                            sx={{
                                background:getBackgroundColor(orderData?.state,100),
                                pt:3,
                                px:3,
                                pb:2,
                                display:"flex",
                                alignItems:"center",
                                fontSize:21,
                                fontWeight:"bold",
                                fontFamily:"Roboto, sans-serif",
                                color: getColor(orderData?.state),
                                borderBottomRightRadius:24,
                                "@media screen and (max-width: 47.9375em)" : {
                                    position:"absolute",
                                    top:0,
                                },
                                "@media screen and (max-width: 566px)" : {
                                    display:"none"
                                },
                                position:"relative",
                                cursor:"pointer",
                                "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    height: '100%',
                                    width: '100%',
                                    borderBottomRightRadius:24,
                                    background: "transparent",
                                    zIndex: 1,
                                    pointerEvents: "none",
                                    transition: ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) ? 
                                        "background 200ms cubic-bezier(0.4, 0, 0.2, 1), border 200ms cubic-bezier(0.4, 0, 0.2, 1)" : "none",
                                },
                                "&:hover&::before": {
                                    background: ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) ?
                                        "rgba(0,0,0, 0.2)" : "transparent"
                                }
                            }}
                        >
                           {t(orderData?.state)}
                           {orderData && hasModifyPrivilege && ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) &&
                           <EditIcon sx={{marginLeft:1.5}} />
                           }
                        </Box>
                        {orderData &&
                            <Box
                                sx={{
                                    pb:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"none"
                                    }
                                }}
                            >
                                <ButtonGroup version={2} isSidepanel>
                                    {orderData && ![general_states.SHIPPED, general_states.FULLYPICKED, general_states.PICKING_IN_PROGRESS, general_states.CANCELLED].includes(orderData?.state) &&
                                        (orderData.amountOfProducts > 1 && hasModifyPrivilege) &&
                                        <Shbutton
                                            block
                                            onClick={() => addModal.setOpen(true)}
                                            className={"group-btn"}
                                            // onClick={props.action}
                                            color={"primaryGrey"}
                                            variant="contained"
                                            // endIcon={<SaveIcon />}
                                        >
                                            {t('split_order')}
                                            </Shbutton>
                                    }
                                    {hasModifyPrivilege &&
                                    <Shbutton
                                        block
                                        onClick={() => confirmDuplicateModal.setOpen(true)}
                                        className={"group-btn"}
                                        color={"primary"}
                                        variant="contained"
                                        endIcon={<ContentCopyIcon />}
                                    >
                                        {t('duplicate_order')}
                                    </Shbutton>
                                    }
                                    {hasCreateReturnPermission && orderData?.state === general_states.SHIPPED && 
                                        <Shbutton
                                            block
                                            onClick={() => returnModal.setOpen(true)}
                                            className={"group-btn"}
                                            // onClick={props.action}
                                            color={"primaryGrey"}
                                            variant="contained"
                                            // endIcon={<SaveIcon />}
                                        >
                                            {t('create_return')}
                                        </Shbutton>
                                    }
                                    {hasCreateReturnPermission && orderData?.state !== general_states.CANCELLED && orderData?.state !== general_states.SHIPPED && 
                                    <Shbutton
                                        block
                                        onClick={() => shipmentModal.setOpen(true)}
                                        className={"group-btn"}
                                        color={"primary"}
                                        variant="contained"
                                    >
                                        {t('create_shipment')}
                                    </Shbutton>
                                    }
                                </ButtonGroup>
                            </Box>
                        }

                        <Box
                            sx={{
                                display:"unset",
                                "@media screen and (min-width: 47.9375em)" : {
                                    display:"none",
                                }
                            }}
                        >
                            {/* {console.log(strategyData?.state)} */}
                            <SidePanelActions orderId={props.orderId} state={orderData?.state} setIsExtended={setIsExtendedCallback} />
                        </Box>

                    </Box>

                    <Box
                        sx={{
                            px:3,
                            pt:4,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column"
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    alignItems:"center",
                                    flexDirection:"column",
                                    mb:3,
                                }
                            }}
                        >
                            <Box
                                sx={{

                                    display:"flex",
                                    alignItems:"center",
                                    gap:2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:1,
                                    }}
                                >
                                    <Subheading>{`${t("order")} ${!isRelation ? orderData?.barcode : orderData?.webshopreference}`}</Subheading>
                                </Box>

                            </Box>
                            <Box>
                                <Text>{t(orderData?.relation?.name ?? t("unknown"))}</Text> <Text light italic>({orderData?.webshopcredentials?.description})</Text>
                            </Box>
                        </Box>
{/* <Subheading */}
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    gap:2,
                                    // flexDirection:"column"
                                },
                                "@media screen and (max-width: 566px)" : {
                                    width:1,
                                    flexDirection:"column"
                                }
                            }}
                        >

                            <Box
                                sx={{
                                    // height:40,
                                    lineHeight:"40px",
                                    background:getBackgroundColor(orderData?.state,100),
                                    px:3,
                                    fontSize:14,
                                    fontWeight:"bold",
                                    fontFamily:"Roboto, sans-serif",
                                    color: getColor(orderData?.state),
                                    borderRadius:20,
                                    display:"none",
                                    alignItems:"center",
                                    flex:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"flex",
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        justifyContent:"center"
                                    }
                                }}
                            >
                                {t(orderData?.state)}
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:4,
                                    borderRadius:7,
                                    height:56,
                                    px:4,
                                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                                    "@media screen and (max-width: 47.9375em)" : {
                                        // width:400,
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        flexDirection:"column",
                                        gap:2,
                                        height:"unset",
                                        px:3,
                                        py:3,
                                    }
                                }}
                            >
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    "@media screen and (max-width: 566px)" : {
                                        flexDirection:"row",
                                        width:1,
                                        justifyContent:"space-between",
                                        gap:2,
                                    }
                                }}
                            >
                                <Text light>{t("orderlines_count")}</Text>
                                <Text>{orderData?.amountOfLines ?? "-"}</Text>
                            </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                            alignItems:'center',
                                            gap:2,
                                        }
                                    }}
                                >
                                    <Text sx={{flexShrink:0}} light noBreak>{t("date_planned_shipment")}</Text>

                                    {hasModifyPrivilege && ![general_states.SHIPPED, general_states.PICKING_IN_PROGRESS, general_states.FULLYPICKED, general_states.CANCELLED].includes(orderData?.state) ? 
                                        <Box
                                            sx={{
                                                width:1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width:1,
                                                    display:"unset",
                                                    "@media screen and (max-width: 566px)" : {
                                                        display:"none"
                                                    }

                                                }}
                                            >
                                                <ShDateRangePicker
                                                    explicit={true}
                                                    singleSelect={true}
                                                    startDate={orderData?.date_planned}
                                                    select={(value) => saveDate(value[0].startDate)}
                                                    size={20}
                                                >
                                                </ShDateRangePicker>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width:1,
                                                    display:"none",
                                                    "@media screen and (max-width: 566px)" : {
                                                        display:"unset"
                                                    }

                                                }}
                                            >
                                                <ShDateRangePicker
                                                    explicit={true}
                                                    singleSelect={true}
                                                    startDate={orderData?.date_planned}
                                                    select={(value) => saveDate(value[0].startDate)}
                                                    size={32}
                                                >
                                                </ShDateRangePicker>
                                            </Box>
                                        </Box>
                                    : 
                                        <Text>{datePlanned}</Text>
                                    }
                                  
                                </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("stream")}</Text>
                                    <Text>{orderData?.stream ? orderData?.stream?.shortName : "-"}</Text>
                                </Box>
                                
                            </Box>
                            
                        </Box>

                    </Box>
                </Box>
            </Sidepanel2>
            <MSplitOrder orderId={props.orderId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            <MUpdateOrderState orderId={props.orderId} state={orderData?.state} open={updateModal.open} onOpen={() => updateModal.setOpen(true)} onClose={() => updateModal.setOpen(false)} handleClose={handleCloseUpdate}/>
            <MpAddReturn 
                onOpen={() => returnModal.setOpen(true)} 
                onClose={() => returnModal.setOpen(false)} 
                open={returnModal.open} 
                handleClose={handleReturnClose} 
                data={orderData}
                />
            <MAddShipment 
                onOpen={() => shipmentModal.setOpen(true)} 
                onClose={() => shipmentModal.setOpen(false)} 
                open={shipmentModal.open} 
                handleClose={handleShipmentClose} 
                data={orderData}
                />
            <MConfirmationWithValue
                open={confirmDuplicateModal.open}
                handleClose={() => confirmDuplicateModal.setOpen(false)}
                data={confirmDuplicateModal.data}
                confirm={confirmDuplicateModal.confirm}
            />
            {/* <MEditDatePlanned orderId={props.orderId} date_planned={orderData?.date_planned} open={editDateModal.open} onOpen={() => editDateModal.setOpen(true)} onClose={() => editDateModal.setOpen(false)} handleClose={handleCloseEditDate}/> */}
        </>
    );
};

export default SpOrderDetails;