import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import Text from "../../../../theme/text/Text";

import { useEffect, useState } from "react";
import Subheading from "../../../../theme/text/Subheading";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ShValidatedInput, { ShValidatedArea } from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../../../utils/validation";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";
import { useUpdateOrderMutation } from "../../../../../newapi/order/ordersSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest, showToastMessage} from "../../../../../utils/toasts";
import SaveIcon from '@mui/icons-material/Save';
import { countriesFromLocale } from '../../../../../utils/countries';
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { useSelector } from 'react-redux';
import { UserPrivileges } from "../../../../../utils/permissions";
import { selectUserPermission } from '../../../../../newapi/user/authentication/authSlice';
import { general_states } from "../../../../../utils/staticEnums";
import EMGeneralExplainerBox from "../../../ModalsPopover/explainers/EMGeneralExplainerBox";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return props.hasPermission && props.canEdit ?
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.onclick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup> : <></>;
};

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    

};

const ShipOrder = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});
    const [canEdit, setCanEdit] = useState(false);
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);
    const hasOrderManagePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.OUTBOUND_MANAGE, UserPrivileges.RP_ORDER_MANAGE])
    );

    // {
    //     "street": "Horstenstraat",
    //     "houseNumber": "35",
    //     "houseNumberAddition": null,
    //     "addressLine1": "Horstenstraat 35",
    //     "addressLine2": "",
    //     "postalCode": "3600",
    //     "city": "Genk",
    //     "country": "BE"
    // }

    const [orderData, setOrderData] = useState({
        shippingAddress: {
            street: "",
            houseNumber: "",
            houseNumberAddition: "",
            addressLine1: "",
            addressLine2: "",
            postalCode: "",
            city: "",
            country: "",
            state: "",
            phone: "",
            email: "",
        },
        remark: ""
    });

    useEffect(() => {
        if(props.data){
            setOrderData(prevState => ({
                ...prevState,
                shippingAddress: {
                    street: props.data.shippingAddress?.street,
                    houseNumber: props.data.shippingAddress?.houseNumber,
                    houseNumberAddition: props.data.shippingAddress?.houseNumberAddition,
                    addressLine1: props.data.shippingAddress?.addressLine1,
                    addressLine2: props.data.shippingAddress?.addressLine2,
                    postalCode: props.data.shippingAddress?.postalCode,
                    state: props.data.shippingAddress?.state,
                    city: props.data.shippingAddress?.city,
                    email: props.data.shippingAddress?.email,
                    phone: props.data.shippingAddress?.phone,
                    country: props.data.shippingAddress?.country,
                },
                remark: props.data.remark,
                // ...props.data,
            }));
        
            setCanEdit(![general_states.SHIPPED, general_states.PICKING_IN_PROGRESS, general_states.FULLYPICKED].includes(props.data.state));
        }
    }, [props.data]);

    const handleChange = (key, value) => {
        if (key === 'remark') {
            setOrderData(prevState => ({
                ...prevState,
                remark: value
            }));
        } else {
            setOrderData(prevState => ({
                ...prevState,
                shippingAddress: {
                    ...prevState.shippingAddress,
                    [key]: value
                }
            }));
        }
        // Optionally clear the error for that field
        setFieldErrors(prevState => ({
          ...prevState,
          [key]: ''
        }));
    };
    
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions isLoading={isLoading} canEdit={canEdit} hasPermission={hasOrderManagePermission} onclick={handleSaveClick}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[ /* Add dependencies here once we have status data */]);
    
    const [EditOrder,{isLoading}] = useUpdateOrderMutation();
    const handleSaveClick = async () => {
        try {
            if (isLoading) return;
            
            const updateObject = {
                shippingAddress: orderData.shippingAddress,
                remark: orderData.remark,
                date_planned: orderData.shippingAddress?.date_planned,
                id: props.data.id
            };
            
            const response = await EditOrder(updateObject).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
              }
        }
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={1.5} paddingY={1.5} overflow={"auto"}>
            {/* Details */}
            <Box 
                sx={{
                    display:"flex",
                    borderRadius:3,
                    overflow:"hidden",
                    marginBottom:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        marginTop:0,
                        background:"none",
                        flexDirection:"column",
                        overflow:"unset"
                    }
                }}
            >
                {/* Editable content */}
                <Box padding={1.5} paddingRight={4} paddingBottom={3} flex={1}
                    sx={{
                        "@media screen and (max-width: 47.9375em)" : {
                            paddingY:0,
                            paddingRight:1.5,
                        }
                    }}
                >
                    <Box 
                        sx={{
                            flex:1,
                            display:"flex",
                            // alignItems:"center",
                            flexDirection:"column",
                            "@media screen and (max-width: 47.9375em)" : {
                                gap:0.5,
                                alignItems:"flex-start",
                            }
                        }}
                    >
                        <Subheading>{t("details")}</Subheading>
                        <Text>{props.data?.deliveryName}</Text>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            mt:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column"
                            }
                        }}
                    >
                        <Box
                            sx={{
                                flex:1,
                                display:"flex",
                                flexDirection:'column',
                                gap:1.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    gap:1,
                                    alignItems:'center',
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140,
                                        display:"flex",
                                        alignItems:"center",
                                    }}
                                >
                                    <Text light>{t("address1")}</Text>
                                    <EMGeneralExplainerBox
                                        title={t("em_title_address1")}
                                        text={t("em_text_address1")}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <ShValidatedInput
                                            readOnly={!hasOrderManagePermission}
                                            name="addressLine1"
                                            value={orderData.shippingAddress?.addressLine1 ?? ""}
                                            onChange={handleChange}
                                            error={fieldErrors.shippingAddress?.addressLine1}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    gap:1,
                                    alignItems:'center',
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140,
                                        
                                    }}
                                >
                                    <Text light>{t("address2")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                    <ShValidatedInput
                                        readOnly={!hasOrderManagePermission}
                                        name="addressLine2"
                                        value={orderData.shippingAddress?.addressLine2 ?? ""}
                                        onChange={handleChange}
                                        error={fieldErrors.shippingAddress?.addressLine2}
                                    />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    gap:1,
                                    alignItems:'center',
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140,
                                        display:"flex",
                                        alignItems:"center",
                                    }}
                                >
                                    <Text light>{t("street")}</Text>
                                    <EMGeneralExplainerBox
                                        title={t("em_title_street")}
                                        text={t("em_text_street")}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                    <ShValidatedInput
                                        readOnly={!hasOrderManagePermission}
                                        name="street"
                                        value={orderData.shippingAddress?.street ?? ""}
                                        onChange={handleChange}
                                        error={fieldErrors.shippingAddress?.street}
                                    />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:'center',
                                    // flexDirection:"column",
                                    gap:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 140,
                                        // "@media screen and (max-width: 47.9375em)" : {
                                        //     width:80
                                        // }
                                    }}
                                >
                                    <Text light>{t("house_nr")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        width:200,
                                        // "@media screen and (max-width: 47.9375em)" : {
                                        //     flex:1
                                        // }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        backgroundColor={colors.grey[200]}
                                        sx={{
                                            marginTop:0.5,
                                            background: `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background: `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                            }
                                        }}
                                    >
                                    <ShValidatedInput
                                        sx={{
                                            px: 2, 
                                            width:56, 
                                            height:32,
                                            boxSizing:"border-box",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1,
                                                width:"unset"
                                            }
                                        }}
                                        readOnly={!hasOrderManagePermission}
                                        name="houseNumber"
                                        value={orderData.shippingAddress?.houseNumber ?? ""}
                                        onChange={handleChange}
                                        error={fieldErrors.shippingAddress?.houseNumber}
                                    />
                                        <Box 
                                            sx={{
                                                marginY:0.5,
                                                height:24,
                                                width:"1px",
                                                background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                            }}
                                        />
                                        <ShValidatedInput
                                            disabled={!hasOrderManagePermission}
                                            sx={{px: 2, width:56, height:32,boxSizing:"border-box"}}
                                            name="houseNumberAddition"
                                            value={orderData.shippingAddress?.houseNumberAddition ?? ""}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:'center',
                                    gap:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                            width:140,
                                    }}
                                >
                                    <Text light>{t("postal_code")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <ShValidatedInput
                                            sx={{
                                                px: 2, 
                                                width:96, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}
                                            disabled={!hasOrderManagePermission}
                                            name="postalCode"
                                            value={orderData.shippingAddress?.postalCode ?? ""}
                                            onChange={handleChange}
                                            error={fieldErrors.shippingAddress?.postalCode}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140
                                    }}
                                >
                                    <Text light>{t("city")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                    <ShValidatedInput
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1,
                                                width:"unset"
                                            }
                                        }}
                                        disabled={!hasOrderManagePermission}
                                        name="city"
                                        value={orderData.shippingAddress?.city ?? ""}
                                        onChange={handleChange}
                                        error={fieldErrors.shippingAddress?.city}
                                    />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140
                                    }}
                                >
                                    <Text light>{t("country")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                        }}
                                    >
                                    <ShValidatedInputDropdown
                                            height={32}
                                            disabled={!hasOrderManagePermission}
                                            name="country"
                                            displayName="name"
                                            changeField="alpha2"
                                            options={countries ?? null}
                                            noSelection={t("choose_option")} 
                                            onChange={handleChange}
                                            selected={countries?.findIndex(country => (orderData.shippingAddress?.country && orderData.shippingAddress?.country.toLowerCase() === country.alpha2.toLowerCase())) ?? -1}
                                            error={fieldErrors.shippingAddress?.country}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140
                                    }}
                                >
                                    <Text light>{t("delivery_state")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        backgroundColor={colors.grey[200]}
                                        sx={{
                                        }}
                                    >
                                    <ShValidatedInput
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box",
                                        }}
                                        disabled={!hasOrderManagePermission}
                                        name="state"
                                        value={orderData.shippingAddress?.state ?? ""}
                                        onChange={handleChange}
                                    />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>


                        <Box
                            sx={{
                                flex:1,
                                display:"flex",
                                flexDirection:'column',
                                gap:1.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                    marginTop:4
                                }
                            }}
                        >
                            
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1,
                                        marginTop:4,
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140
                                    }}
                                >
                                    <Text light>{t("phone")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                        }}
                                    >
                                    <ShValidatedInput
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box",
                                        }}
                                        disabled={!hasOrderManagePermission}
                                        name="phone"
                                        value={orderData.shippingAddress?.phone ?? ""}
                                        onChange={handleChange}
                                        error={fieldErrors.shippingAddress?.phone}
                                    />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:140
                                    }}
                                >
                                    <Text light>{t("email")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                    
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <ShValidatedInput
                                            sx={{
                                                px: 2, 
                                                flex:1, 
                                                height:32,
                                                boxSizing:"border-box"
                                            }}
                                            disabled={!hasOrderManagePermission}
                                            name="email"
                                            value={orderData.shippingAddress?.email ?? ""}
                                            onChange={handleChange}
                                            error={fieldErrors.shippingAddress?.email}
                                        />
                                    </Box>
                                </Box>
                            </Box>


                            <Box
                                sx={{
                                    flex:1,
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("remark")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        flex:1,
                                        height:132,
                                        overflow:"hidden",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <ShValidatedArea
                                        sx={{
                                            masterBox:{
                                            },
                                            inputBox:{
                                                // display:"flex",
                                                // alignItems:"flex-start",
                                            },
                                            input: {
                                                lineHeight:"unset",
                                            }
                                        }}
                                        disabled={!hasOrderManagePermission}
                                        name="remark"
                                        value={orderData.remark ?? ""}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Maps */}
                {/* <Box 
                    sx={{
                        width:200,
                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]
                    }}
                ></Box> */}
            </Box>
            <SpDesktopFooter>
                {/* Pass the necessary state values and setter functions to GridActions */}
                <GridActions
                    isLoading={isLoading}
                   onclick={handleSaveClick}
                   canEdit={canEdit}
                   hasPermission={hasOrderManagePermission}
                />
            </SpDesktopFooter>
        </Box>
    );
}


export default ShipOrder;