import { Box, List, useTheme } from "@mui/material"
import SHModal from "../../theme/Modal/SHModal"
import Shbutton from "../../theme/buttons/Shbutton"
import { tokens } from "../../../theme";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import SelectGroup from "../../theme/Select/SelectGroup";
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import Heading from "../../theme/text/Heading";


const MConfirmDelete = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();


    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const confirm = () => {
        props.confirm();
        props.handleClose();
    }

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>
                    {props.title ? props.title : t("delete_confirm_title")}
                </Subheading>
                <Subheading light>{props.data}</Subheading>
            </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    textAlign:"center",
                }}
            >
                <Text>
                    {props.content ? props.content : t("are_you_sure")}
                </Text>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent: "space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                        // display:"none",
                    }
                }}
            >
                
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>

                <ButtonGroup version={2}>
                    <Shbutton block className={"group-btn"} color={"red"} onClick={confirm} variant="contained">
                        {/* {t("delete")}  */}
                        {props.confirmBtn ? props.confirmBtn : t("delete")}
                    </Shbutton>

                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MConfirmDelete;