import { Box, ButtonBase, Slide, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../theme";
import Heading from "../../../../../components/theme/text/Heading";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useCancelShipmentMutation, useGetShipmentLabelMutationMutation, useReplaceShipmentMutation } from "../../../../../newapi/order/ordersSlice";
import { general_states } from "../../../../../utils/staticEnums";
import Text from "../../../../../components/theme/text/Text";
import CloseIcon from '@mui/icons-material/Close';
import { format } from "date-fns";
import cx from 'classnames';
import { useGetShippersSelectWithProfilesQuery } from "../../../../../newapi/shipper/shipperSlice";
import { getShipperColorSettingsByType } from "../../../../../components/Streams/StreamShipperSettings";
import Subheading from "../../../../../components/theme/text/Subheading";

import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { DropdownBase } from "../../../../../components/theme/dropdowns/Dropdown";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { showToastMessage } from "../../../../../utils/toasts";
import Truck from "../components/truck/Truck";
import MConfirmDelete from "../../../../../components/global/Modals/MConfirmDelete";

const SelectedOrderView =  (props) => {
    const { t, ready,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 

    const [shipmentHighlightColor, setShipmentHighlightColor] = useState('none');
    const [highlightColor, setHighlightColor] = useState('none');
    const [labelScale, setLabelScale] = useState(1);
    const [open, setOpen] = useState(false);

    const [selectedShipment, setSelectedShipment] = useState();
    const [selectedOrderSelectShipper, setSelectedOrderSelectShipper] = useState(null);
    const [selectedOrderSelectShipperProfile, setSelectedOrderSelectShipperProfile] = useState(null);

    const [getShipmentLabel, { data: shipmentLabelData, isLoading: shipmentLabelIsLoading, isError: shipmentLabelIsError }] = useGetShipmentLabelMutationMutation();
    const [cancelShipment, { data: cancelShipmentData, isLoading: cancelShipmentLoading, isError: cancelShipmentError }] = useCancelShipmentMutation();
    const [replaceLabelResponse, {isLoading: replaceLoading,isFetching:replaceFetching}] = useReplaceShipmentMutation();

    const { data: shippersSelected } = useGetShippersSelectWithProfilesQuery({warehouseId: props.selectedOrder?.warehouseId},
        {
            skip: !props.selectedOrder
        }
    );

    const selectedOrderSelectNewShipper = (selected) => {
        var shipper = shippersSelected?.filter(shipper => shipper.type !== 'bol' || props.selectedOrder.webshopcredentials?.webshop_type === 'bol')[selected];
        setSelectedOrderSelectShipper(shipper);
        setSelectedOrderSelectShipperProfile(-1);
    }
    const selectedOrderSelectNewShipperProfile = (selected) => {
        var shipperProfile = selectedOrderSelectShipper?.shipperProfiles[selected];
        setSelectedOrderSelectShipperProfile(shipperProfile);
    }

    useEffect(() => {
        if(props.selectedOrder) {
            const unsyncedShipments = props.selectedOrder.shipments.filter(shipment => !shipment.synced && shipment.state !== general_states.CANCELLED);
            if(unsyncedShipments.length === 0) {
                // props.setHighlightColor("red");
            } else {
                setSelectedShipment(unsyncedShipments.sort((a, b) => new Date(b.shipmentDate) - new Date(a.shipmentDate))[0]);
            }
        }
    },[props.selectedOrder])

    useEffect(() => {
        if(!shippersSelected || !props.selectedOrder) return;
        setSelectedOrderSelectShipper(shippersSelected.filter(shipper => shipper.id === props.selectedOrder?.shipper?.id)[0])
        const shipperProfileId = props.selectedOrder?.shipperProfile?.id;
        const selectedShipperProfile = shippersSelected.flatMap(shipper => shipper.shipperProfiles).find(profile => profile.id === shipperProfileId);
        if(selectedShipperProfile){
            setSelectedOrderSelectShipperProfile(selectedShipperProfile);
        } 
    }, [shippersSelected,props.selectedOrder])

    useEffect(() => {
        if (shipmentHighlightColor !== 'none') {
            setTimeout(() => setShipmentHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [shipmentHighlightColor]);

    useEffect(() => {
        if (highlightColor !== 'none') {
            setTimeout(() => setHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [highlightColor]);


    useEffect(() => {
        if (labelScale !== 1) {
            setTimeout(() => setLabelScale(1), 500); // Duration to stay colored
        }
    }, [labelScale]);

    const [shipmentToDelete,setShipmentToDelete] = useState(null);

    const confirmDelete = async ()  => {
        await cancelShipment(shipmentToDelete.id).unwrap().then(res => {
            props.setSelectedOrder((prevState) => {
                // Create a shallow copy of the shipments array
                const updatedShipments = [...prevState.shipments];
        
                const shipmentIndex = updatedShipments.findIndex(locShipment => locShipment.id === shipmentToDelete.id);
        
                if (shipmentIndex !== -1) {
                // Update the state of the found shipment to 'cancelled'
                updatedShipments[shipmentIndex] = {
                    ...updatedShipments[shipmentIndex], // Keep other properties the same
                    state: general_states.CANCELLED // Update the status to cancelled
                };
                }
        
                // Return the updated state with the modified shipments array
                return { ...prevState, shipments: updatedShipments };
            });
            // console.log(res);
        }).catch(err => {
            console.error(err);
        })
     }

    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }


    const replaceLabel = async () => {
        try {
            const response = await replaceLabelResponse({
                "id": selectedShipment.id,
                "shipperId": selectedOrderSelectShipper.id,
                "shipperProfileId": selectedOrderSelectShipperProfile.id,
                "workspaceId": props.selectedWorkSpace.id,
            }).unwrap();

            if(response.success){
                if(response.openFiles){
                    for (let i = 0; i < response.labelContent.length; i++) {
                        const content = response.labelContent[i];
                        let url = "";
                        if(response.labelType === 'pdf'){
                            const decodedData = atob(content);
                            const arrayBuffer = new ArrayBuffer(decodedData.length);
                            const uint8Array = new Uint8Array(arrayBuffer);
                            for (let i = 0; i < decodedData.length; i++) {
                                uint8Array[i] = decodedData.charCodeAt(i);
                            }
                            const blob = new Blob([uint8Array], { type: 'application/pdf' });
                            url = URL.createObjectURL(blob);
                        } else if(response.labelType === 'link'){
                            url = content;
                        }
                        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                        if (newWindow) {
                            setTimeout(() => {
                                window.URL.revokeObjectURL(url);
                            }, 500);
                        } else {
                            window.URL.revokeObjectURL(url);
                        }
                    };
                }
                
                setSelectedOrderSelectShipper(null);
                setSelectedOrderSelectShipperProfile(null);
                await props.resetScreen();

            } else {
            
                showToastMessage("error", response.message);
            }
        } catch (error) {
            showToastMessage("error", error.data?.error || error.message);
        }
            
    }

    const ShipperLogoMap = {
        postnl: PostNLLogo,
        dhl: DhlLogo,
        dpd: DpdLogo,
        bol: BolLogo,
      };

    const renderShipperLogo = (shipperType) => {
        // Get the logo component based on shipper type
        const LogoComponent = ShipperLogoMap[shipperType];

        // Render the logo component if it exists, or null otherwise
        return LogoComponent ? <LogoComponent className={shipperType} /> : null;

    };

    const getShipmentBackgroundColor = () => {
        switch (shipmentHighlightColor) {
            case "blue": 
                return theme.palette.mode === "dark" ? colors.blue[300] : colors.blue[200];
            case 'orange':
                return colors.orange[400];
            case 'green':
                return colors.green[400];
            case 'red':
                return colors.red[400];
            default:
                return theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0];
        }

    }
    const getBackgroundColor = () => {
        switch (highlightColor) {
            case "blue": 
                return theme.palette.mode === "dark" ? colors.blue[300] : colors.blue[400];
            case 'orange':
                return colors.orange[400];
            case 'green':
                return colors.green[400];
            case 'red':
                return colors.red[400];
            default:
                if(selectedOrderSelectShipper) {
                    return getShipperColorSettingsByType(selectedOrderSelectShipper?.type).background;
                }
                return theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0];
        }
    };
    return (
        <Box
            sx={{
                flex:1,
                flexDirection: "column",
                display:"flex",
                position:'relative',
                overflow:"hidden",
                // background: getBackgroundColor(),
                // transition: 'background-color 0.5s'
            }}
        >
            <Box
                sx={{
                    flex:1,
                    flexDirection: "column",
                    display:"flex",
                    p:3,
                    borderRadius:4,
                    position:'relative',
                    // borderTopLeftRadius:16,
                    // borderTopRightRadius:16,
                    // background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                    overflow:"hidden",
                    background: getShipmentBackgroundColor(),
                    transition: 'background-color 0.5s',
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:1,
                        mb:3,
                    }}
                >
                    <Heading>{t("order")}</Heading>
                    <Heading color={theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}>{props.selectedOrder.deliveryName}</Heading>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        overflow:'scroll',
                        alignItems:"flex-start",
                        position:'relative',
                        gap:3,
                    }}
                >
                    <Box
                        sx={{
                            flex:1,
                        }}
                    >
                        {[...props.selectedOrder.shipments] // Create a shallow copy of the array
                            .sort((a, b) => new Date(b.shipmentDate) - new Date(a.shipmentDate)) // Sort by shipmentDate, descending
                            .map((shipment, index) => {
                            // if(row.amountEchecked >= row.amount) return null;
                            return (
                                <Box
                                    key={"shipment-list"+index}
                                    sx={{
                                        pb:3,
                                        ml:3.5,
                                        position:'relative',
                                        "&:before" : {
                                            zIndex:2,
                                            content:'""',
                                            display:'block',
                                            width:6,
                                            height:6,
                                            background: theme.palette.mode === "dark" ? colors.txt["softenedMore"] : colors.grey[400],
                                            position:'absolute',
                                            top:10,
                                            left:-24,
                                            borderRadius:1,
                                        },
                                        "&:after" : {
                                            zIndex:1,
                                            content:'""',
                                            display:'block',
                                            width:2,
                                            height:1,
                                            background: theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100],
                                            position:'absolute',
                                            top:10,
                                            left:-22,
                                            borderRadius:1,
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display:"flex",
                                            // justifyContent:'space-between',
                                        }}
                                    >
                                        <Text bold>
                                            {new Date(shipment?.shipmentDate).toLocaleString(i18n.language, {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            })}
                                        </Text>&nbsp;
                                        <Text italic>
                                            {new Date(shipment?.shipmentDate).toLocaleString(i18n.language, {
                                                day: '2-digit',
                                                month: 'long'
                                            })}
                                        </Text>
                                    </Box>
                                    <Box
                                        className={cx('',{
                                            'active' : selectedShipment?.id === shipment.id,
                                            'cancelled': shipment.state === general_states.CANCELLED,
                                        })}
                                        // onClick={() => {
                                        //     setSelectedShipment(shipment)
                                        // }}
                                        key={index}
                                        sx={{
                                            display:"flex",
                                            flexDirection:"column",
                                            alignItems:"flex-start",
                                            mt:0.5,
                                            width:1,
                                            boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                            border:`1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100]}`,
                                            p:2,
                                            borderRadius:4,
                                            justifyContent:"space-between",
                                            background:  theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100],
                                            "&.active" : {
                                                background:  theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                                                border:`1px solid ${theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100]}`,
                                            },
                                            "&.cancelled" : {
                                                background: colors.red[100]
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width:1,
                                                display:'flex',
                                                justifyContent:'space-between',
                                            }}
                                        >
                                            <Text bold variant={shipment.state === general_states.CANCELLED ? "red" : null}>
                                                {shipment.trackingNumber}
                                            </Text>
                                                <Box
                                                    sx={{
                                                        display:'flex',
                                                        gap:1,
                                                    }}
                                                >
                                                    <ShLabel
                                                        clickable
                                                        palette={shipment.state === general_states.CANCELLED ? "normal" : "pastel"}
                                                        // palette={order.shipments.length > 0 ? "pastel" : "pastel"}
                                                        variant={shipment.state === general_states.CANCELLED ? "red" : shipment.synced ? "purple" : "orange"}
                                                        fitted
                                                        size={32}
                                                    >
                                                        {shipment.state === general_states.CANCELLED ? t("cancelled") : shipment.synced ? t("synced") : t("booked")}
                                                    </ShLabel>
                                                    {!shipment.synced && shipment.state !== general_states.CANCELLED &&
                                                        <ButtonBase
                                                            onClick={async () => {
                                                                setShipmentToDelete(shipment);
                                                                confirmDeleteModal.setOpen(true);
                                                            }}
                                                            key={index}
                                                            sx={{
                                                                display:"flex",
                                                                alignItems:"center",
                                                                justifyContent:"center",
                                                                width:32,
                                                                height:32,
                                                                borderRadius:4,
                                                                background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[400],
                                                                color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"],
                                                                fontWeight:"bold"
                                                            }}
                                                        >
                                                            <CloseIcon fontSize="small"/>
                                                        </ButtonBase>
                                                    }
                                                </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                width:1,
                                                display:'flex',
                                                mt:2.5,
                                                gap:2,
                                            }}
                                        >
                                            <ButtonBase
                                                onClick={async () => {
                                                    await getShipmentLabel(shipment.id).unwrap().then(res => {
                                                        res.labels?.forEach(label => {
                                                            const decodedData = atob(label);
                                                            const arrayBuffer = new ArrayBuffer(decodedData?.length);
                                                            const uint8Array = new Uint8Array(arrayBuffer);
                                                            for (let i = 0; i < decodedData.length; i++) {
                                                                uint8Array[i] = decodedData.charCodeAt(i);
                                                            }
                                                            const blob = new Blob([uint8Array], { type: 'application/pdf' });
                                                            const pdfUrl = URL.createObjectURL(blob);
                                                            window.open(pdfUrl, '_blank');
                                                        });
                                                    }).catch(err => {
                                                        // console.error(err);
                                                    })
                                                }}      
                                                className={cx('',{
                                                    'active' : selectedShipment?.id === shipment.id,
                                                    'cancelled': shipment.state === general_states.CANCELLED,
                                                })}
                                                sx={{
                                                    borderRadius:5,
                                                    boxShadow:'none',
                                                    height:40,
                                                    flex:1,
                                                    border:`2px solid ${ theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400]}`,
                                                    background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200],
                                                    fontSize:14,
                                                    fontWeight:"bold",
                                                    fontFamily:"Roboto,sans-serif",
                                                    color: colors.txt["primary"],
                                                    "&.active" : {
                                                        border:`2px solid ${theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400]}`,
                                                        background:theme.palette.mode === "dark" ? colors.blue[100]: colors.primary[100],
                                                        color:theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400],
                                                    },
                                                    "&.cancelled" : {
                                                        background:colors.red[400],
                                                        border:`2px solid ${colors.red[400]}`,
                                                        color:theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["white"],
                                                    }
                                                }}
                                            >
                                                {t("download_label")}
                                                
                                            </ButtonBase>
                                            {!shipment.synced && shipment.state !== general_states.CANCELLED ?
                                                <ButtonBase
                                                    onClick={() => {
                                                        setLabelScale(1.35)
                                                        setHighlightColor("blue");
                                                        setSelectedShipment(shipment);
                                                    }}
                                                    className={cx('',{
                                                        'active' : selectedShipment?.id === shipment.id,
                                                    })}
                                                    sx={{
                                                        borderRadius:5,
                                                        boxShadow:'none',
                                                        height:40,
                                                        flex:1,
                                                        border:`2px solid ${theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400]}`,
                                                        background:theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400],
                                                        fontSize:14,
                                                        fontWeight:"bold",
                                                        fontFamily:"Roboto,sans-serif",
                                                        color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"],
                                                        "&.active" : {
                                                            border:`2px solid ${theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400]}`,
                                                            background:theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400],
                                                            color:theme.palette.mode === "dark" ? colors.txt["primaryDark"]: colors.txt["white"],

                                                        }
                                                    }}
                                                >
                                                    {t("replace_label")}
                                                    
                                                </ButtonBase>
                                            :null}
                                            

                                        </Box>
                                        {/* <Box
                                            sx={{
                                                width:1,
                                                display:"flex",
                                                justifyContent:"space-between"
                                            }}
                                        >
                                            <Text bold>{order.deliveryName}</Text>
                                            <ShLabel
                                                clickable
                                                // palette={order.shipments.length > 0 ? "pastel" : "pastel"}
                                                variant={order.shipments.length > 0 ? "lightblue" : order.shipments[0].synced ? "purple" : "orange"}
                                                fitted
                                                borderless
                                                size={32}
                                            >
                                                <b>{order.shipments.length}</b> {t("shipments")}
                                            </ShLabel>
                                        </Box> */}
                                        
                                        {/* <Box
                                            sx={{
                                                mt:1,
                                                display:"flex",
                                                flexDirection:"column"
                                            }}
                                        >
                                            <Text>{order.deliveryStreet} {order.deliveryHousenumber}</Text>
                                            <Text>{order.deliveryPostalCode}, {order.deliveryCity}</Text>
                                        </Box> */}

                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box
                        sx={{
                            minHeight:120,
                            // height:50,
                            position:'sticky',
                            top:0,
                            borderRadius:4,
                            p:1.5,
                            flex:1,
                            boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                            border:`1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100]}`,
                            // background: theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0],
                            background: getBackgroundColor(),
                            transition: 'background-color 0.5s',
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                justifyContent:"space-between",
                            }}
                        >
                            <Box
                                sx={{
                                    pl:1.5,
                                    pt:1.5,
                                }}
                            >
                                <Subheading
                                    color={
                                        tokens("white").txt["primary"]
                                    }
                                >{t("replace_label")}</Subheading>
                                <Text bold 
                                    color={
                                        selectedOrderSelectShipper ? 
                                            getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base 
                                        : 
                                            theme.palette.mode === "dark" ? 
                                                colors.blue[400] 
                                            : 
                                                colors.primary[400]
                                    }
                                    sx={{
                                        transform:`scale(${labelScale})`,
                                        transformOrigin:"left top",
                                        transition:"transform 0.5s"
                                    }}
                                >
                                    {selectedShipment?.trackingNumber}
                                </Text>
                            </Box>
                            <Box
                                sx={{
                                    mt:1.5,
                                    mr:1.5,
                                    mb:1,
                                    height:40,
                                    display:"flex",
                                    alignItems:'center',
                                    px:2,
                                    pl:0.5,
                                    gap:1.5,
                                    borderRadius:5,
                                    background:getShipperColorSettingsByType(selectedOrderSelectShipper?.type).background,
                                    color: getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base,
                                    fontWeight:"bold",
                                    border: `1px solid ${getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base}`,
                                    "& .postnl" : {
                                        width:24,
                                        height:24,
                                    },
                    
                                    "& .dpd" : {
                                        width:24,
                                        height:24,
                                    },
                                    "& .dhl" : {
                                        width:24,
                                        height:24,
                                    },
                                    "& .bol" : {
                                        width:32,
                                        // width:24,
                                        height:16,
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        height:32,
                                        width:32,
                                        borderRadius:4,
                                        display:"flex",
                                        alignItems:'center',
                                        justifyContent:'center',
                                        background:getShipperColorSettingsByType(selectedOrderSelectShipper?.type).streamrule,
                                        fill:"white",
                                        "& svg" : {
                                            // fill:"white",
                                        },
                                        "& svg *" : {
                                            // fill:"white",
                                        }
                                    }}

                                >
                                    {renderShipperLogo(selectedOrderSelectShipper?.type)}
                                </Box>
                                {selectedOrderSelectShipper?.name}
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                p:1.5,
                                mb:1,
                            }}
                        >
                            <Text
                                color={
                                    tokens("white").txt["primary"]
                                }
                            >
                                {props.selectedOrder?.shippingAddress?.addressLine1}
                            </Text>
                            <br/>

                            
                            <Text
                                color={
                                    tokens("white").txt["primary"]
                                }
                            >
                                {props.selectedOrder?.shippingAddress?.postalCode}, {props.selectedOrder.shippingAddress?.city}
                            </Text>
                            <br/>
                            
                            <Text
                                color={
                                    tokens("white").txt["primary"]
                                }
                            >
                                {props.selectedOrder?.shippingAddress?.state}, {props.selectedOrder.shippingAddress?.country}
                            </Text>
                            <br/>
                        </Box>

                        <Box
                            sx={{
                                background:getShipperColorSettingsByType(selectedOrderSelectShipper?.type).streamrule,
                                borderRadius:3,
                                p:1.5,
                            }}
                        >
                            <Box>
                                <Text color={tokens("white").txt["primary"]}  light>{t("carrier_advice")}:</Text><br/>
                                <Text color={getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base} bold>
                                    {props.selectedOrder.suggestedCarrier && props.selectedOrder.suggestedCarrier.id > 0 ? 
                                        props.selectedOrder.suggestedCarrier.name : 
                                        "Geen drageradvies"
                                    }
                                </Text>
                            </Box>
                            <Box
                                sx={{
                                    mt:2,
                                }}
                            >
                                
                                <Box
                                    sx={{
                                    }}
                                >
                                    <Text color={tokens("white").txt["primary"]}  light>{t("shipper")}:</Text>
                                    <Box 
                                        sx={{
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:'space-between',
                                        }}
                                    >
                                        <Text color={getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base} bold>{selectedOrderSelectShipper?.name}</Text>
                                        {props.selectedOrder?.deliveryMethod !== "vvb" &&
                                        <ButtonBase
                                            sx={{
                                                ml:2,
                                                width:32,
                                                height:32,
                                                background: getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base,
                                                color:"#fff",
                                                borderRadius:5,
                                            }}
                                        >
                                            <DropdownBase
                                                transformOrigin={{
                                                    vertical: "bottom"
                                                }}
                                                anchorOrigin={{
                                                    vertical:"top"
                                                }}
                                                displayName={"name"} 
                                                onChange={(selected) => 
                                                    {
                                                        selectedOrderSelectNewShipper(selected);
                                                    }
                                                }
                                                noSelection={""} //Custom text when nothing is selected
                                                options={shippersSelected?.filter(shipper => shipper.type !== 'bol' || props.selectedOrder?.webshopcredentials?.webshop_type === 'bol')}
                                                selected={-1}
                                                closeOnSelection
                                                resetOnSelection
                                            >
                                                <EditOutlinedIcon/>
                                            </DropdownBase>
                                        </ButtonBase>
                                        }
                                    </Box>
                                </Box>
                                
                            </Box>
                            {selectedOrderSelectShipper?.type !== "bol" && (
                                <Box
                                    sx={{
                                        mt:2,
                                    }}
                                >
                                    <Box >
                                        <Text color={tokens("white").txt["primary"]}  light>{t("shipperprofile")}:</Text><br/>
                                        <Box
                                            sx={{
                                                display:"flex",
                                                justifyContent:'space-between',
                                                alignItems:'center',
                                            }}
                                        >
                                            <Text color={getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base} bold>{selectedOrderSelectShipperProfile?.name}</Text>
                                            <ButtonBase
                                                sx={{
                                                    width:32,
                                                    height:32,
                                                    background:getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base,
                                                    color:"#fff",
                                                    borderRadius:5,
                                                }}
                                            >
                                                <DropdownBase
                                                    transformOrigin={{
                                                        vertical: "bottom"
                                                    }}
                                                    anchorOrigin={{
                                                        vertical:"top"
                                                    }}
                                                    displayName={"name"} 
                                                    // onChange={(selected) => props.selectShipperProfile(selected)}
                                                    onChange={(selected) => 
                                                        {
                                                            selectedOrderSelectNewShipperProfile(selected)
                                                        }
                                                    }
                                                    noSelection={""} //Custom text when nothing is selected
                                                    options={selectedOrderSelectShipper?.shipperProfiles}
                                                    selected={-1}
                                                    closeOnSelection
                                                    resetOnSelection
                                                >
                                                    <EditOutlinedIcon/>
                                                </DropdownBase>
                                            </ButtonBase>
                                        </Box>
                                    </Box>
                                
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                mt:3,
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center",
                                width:1,
                                mb:1.5,
                            }}
                        >
                            <Shbutton
                                block 
                                className={"group-btn"} 
                                isLoading={replaceLoading || replaceFetching} 
                                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                                variant="contained" 
                                onClick={() => replaceLabel()}
                                sx={{
                                    background: getShipperColorSettingsByType(selectedOrderSelectShipper?.type).base, 
                                    color: getShipperColorSettingsByType(selectedOrderSelectShipper?.type).background 
                                }}
                            >
                                {t("print_new_label")}
                            </Shbutton>
                        </Box>
                    </Box>
                </Box>

            </Box>
        {/* <Box
            sx={{
                mt:3,
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
            }}
        >
            <ButtonGroup version={2}>
                <Shbutton 
                    onClick={() => {setprops.selectedOrder(null)}}
                    className={"group-btn"} 
                    color={"red"} 
                    variant="contained"
                >
                    {t('cancel')}
                </Shbutton>
                
            </ButtonGroup>
        </Box> */}

        <Box
            sx={{
                bottom:32,
                position:'absolute',
                width:"60%",
                left:"20%"
            }}
        >
            <Slide
                direction="up" 
                in={replaceLoading || replaceFetching}
            >
                <Box
                    sx={{ 
                        borderRadius:8,
                        background:"#fff",
                        width:1,
                        height:65,
                        display:"flex",
                        justifyContent:"space-between",
                        alignItems:'center',
                        px:3,
                        position:'relative',
                        zIndex:1,
                        // left:"50%",
                        // transform:"translateX(-50%)",
                        overflow:'visible',
                        "&:before" : {
                            position:'absolute',
                            content:"''",
                            left:6,
                            right:6,
                            top:6,
                            bottom:6,
                            zIndex:-1,
                            borderRadius:12,
                            filter:"blur(12px)",
                            background: 'linear-gradient(var(--gradient-angle), #31415f, #0D61FF, #C2D7FF)',
                            animation: 'rotation 5s linear infinite'
                        },
                        "&:after" : {
                            position:'absolute',
                            content:"''",
                            left:0,
                            right:0,
                            top:0,
                            bottom:0,
                            zIndex:-1,
                            borderRadius:12,
                            background:'#fff'
                        }
                    }}
                >
                    <Box width={60} />
                    <Subheading>
                        {t('echeck_processing_order')}
                    </Subheading>
                    <Truck/>
                </Box>
            </Slide>
        </Box>

        <MConfirmDelete
            open={confirmDeleteModal.open}
            handleClose={() => confirmDeleteModal.setOpen(false)}
            // data={confirmDeleteModal.data}
            data={shipmentToDelete?.trackingNumber}
            confirm={confirmDeleteModal.confirm}
            title={t("cancel_shipment")}
            content={t("are_you_sure_you_want_to_cancel_shipment")}
            confirmBtn={t("cancel")}
        />
    </Box>
    )
}

export default SelectedOrderView;