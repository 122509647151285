import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";
import PopoverModal from "./PopoverModal";

const SHQuickAction = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    
    // Separate touch event handler for mobile devices
    const handleTouch = (event) => {
        if (anchorEl) {
            handlePopoverClose();
        } else {
            handlePopoverOpen(event);
        }
    };

    return (
        <>
            {props.children({
                anchorEl,
                onMouseEnter:handlePopoverOpen,
                onMouseLeave:handlePopoverClose,
                onTouchStart:handleTouch,
            })}
            
            <PopoverModal
                id="simple-popover"
                open={Boolean(anchorEl)}
                {...props}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                triangle={{position:props.position ?? "center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
                hover={true} // This prop is used to indicate hover functionality
            >
                <Box
                    sx={{
                        maxWidth:props.maxWidth ?? 450,
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        px:1.5,
                        py:1.5,
                        "@media screen and (max-width:47.9375em)" : {
                            maxWidth:"unset"
                        }
                    }}
                >
                    {props.component}
                </Box>
            </PopoverModal>
        </>
    )
}

export default SHQuickAction;