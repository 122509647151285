import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import Heading from "../../../../../components/theme/text/Heading";
import Subheading from "../../../../../components/theme/text/Subheading";
import EcheckCarrier from "../components/EcheckCarrier";
import { getShipperColorSettingsByType } from "../../../../../components/Streams/StreamShipperSettings";
import Text from "../../../../../components/theme/text/Text";

const PreviousOrderEchecked = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const ShipperLogoMap = {
        postnl: PostNLLogo,
        dhl: DhlLogo,
        dpd: DpdLogo,
        bol: BolLogo,
      };

    const renderShipperLogo = (shipperType) => {
        // Get the logo component based on shipper type
        const LogoComponent = ShipperLogoMap[shipperType];

        // Render the logo component if it exists, or null otherwise
        return LogoComponent ? <LogoComponent className={shipperType} /> : null;

    };

    // if(selectedOrderSelectShipper) {
    //     return getShipperColorSettingsByType(selectedOrderSelectShipper?.type).background;
    // }
    return (

        <Box
            sx={{
                flex:1,
                flexDirection: "column",
                display:"flex",
                p:2,
                borderRadius:4,
                position:'relative',
                // borderTopLeftRadius:16,
                // borderTopRightRadius:16,
                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],

            }}
        >
            <Heading sx={{mb:3}}>
                {t("processed_order")}
            </Heading>

            <Box
                sx={{
                    display:"flex"
                }}
            >
                <Subheading>{props.previousOrder.totalAmount}</Subheading>&nbsp;
                <Subheading light>{props.previousOrder.totalAmount > 1 ? t("products") : t("product")} </Subheading>&nbsp;
                <Subheading semibold variant="purple">{t("processed")}</Subheading>&nbsp;
                <Subheading light>in</Subheading>&nbsp;
                <Subheading>{props.previousOrder.carriers?.length}</Subheading>&nbsp;
                <Subheading light>{t("carriers")}</Subheading>
            </Box>
            <Box
                sx={{
                    mt:2,
                    display:'flex',
                    gap:3,
                }}
            >
                <Box 
                    sx={{
                        flex:1,
                        display:'flex',
                        flexDirection:"column",
                        gap:2,
                    }}
                >
                    {/* {PickedView()} */}
                    {props.previousOrder.carriers && props.previousOrder.carriers?.map((carrier, carrierIndex) => (
                        <EcheckCarrier
                            readOnly
                            carrier={carrier}
                            carrierIndex={carrierIndex}
                        />
                    ))}
                    
                </Box>
                

                <Box
                    sx={{
                        minHeight:120,
                        // height:50,
                        position:'sticky',
                        top:0,
                        borderRadius:4,
                        p:1.5,
                        flex:1,
                        boxShadow:`0px 2px 4px ${getShipperColorSettingsByType(props.previousOrder.shipper.type).streamrulehover}`,
                        border:`1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100]}`,
                        // background: theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0],
                        background: getShipperColorSettingsByType(props.previousOrder.shipper.type).background,
                        transition: 'background-color 0.5s',
                    }}
                >

                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                pl:1.5,
                                pt:1.5,
                            }}
                        >
                            <Subheading
                                color={
                                    tokens("white").txt["primary"]
                                }
                            >
                                {props.previousOrder.order.deliveryName}
                            </Subheading>
                            
                        </Box>
                        <Box
                            sx={{
                                mt:1.5,
                                mr:1.5,
                                mb:1,
                                height:40,
                                display:"flex",
                                alignItems:'center',
                                px:2,
                                pl:0.5,
                                gap:1.5,
                                borderRadius:5,
                                background:getShipperColorSettingsByType(props.previousOrder.shipper.type).background,
                                color: getShipperColorSettingsByType(props.previousOrder.shipper.type).base,
                                fontWeight:"bold",
                                border: `1px solid ${getShipperColorSettingsByType(props.previousOrder.shipper.type).base}`,
                                "& .postnl" : {
                                    width:24,
                                    height:24,
                                },
                
                                "& .dpd" : {
                                    width:24,
                                    height:24,
                                },
                                "& .dhl" : {
                                    width:24,
                                    height:24,
                                },
                                "& .bol" : {
                                    width:32,
                                    // width:24,
                                    height:16,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    height:32,
                                    width:32,
                                    borderRadius:4,
                                    display:"flex",
                                    alignItems:'center',
                                    justifyContent:'center',
                                    background:getShipperColorSettingsByType(props.previousOrder.shipper.type).streamrule,
                                    fill:"white",
                                    "& svg" : {
                                        // fill:"white",
                                    },
                                    "& svg *" : {
                                        // fill:"white",
                                    }
                                }}

                            >
                                {renderShipperLogo(props.previousOrder.shipper.type)}
                            </Box>
                            {props.previousOrder.shipper.name}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p:1.5,
                            mb:1,
                        }}
                    >

                        <Text
                            color={
                                tokens("white").txt["primary"]
                            }
                        >
                            {props.previousOrder.order.shippingAddress?.addressLine1}
                        </Text>
                        <br/>

                        
                        <Text
                            color={
                                tokens("white").txt["primary"]
                            }
                        >
                            {props.previousOrder.order.shippingAddress?.postalCode}, {props.previousOrder.order.shippingAddress?.city}
                        </Text>
                        <br/>
                        
                        <Text
                            color={
                                tokens("white").txt["primary"]
                            }
                        >
                            {props.previousOrder.order.shippingAddress?.state}, {props.previousOrder.order.shippingAddress?.country}
                        </Text>
                        <br/>
                    </Box>
                    <Box
                        sx={{
                            background:getShipperColorSettingsByType(props.previousOrder.shipper.type).streamrule,
                            borderRadius:3,
                            p:1.5,
                        }}
                    >
                        <Box>
                            <Text color={tokens("white").txt["primary"]} >{t("carrier_advice")}:</Text><br/>
                            <Text color={getShipperColorSettingsByType(props.previousOrder.shipper.type).base} bold>
                                {props.previousOrder.order.suggestedCarrier && props.previousOrder.order.suggestedCarrier.id > 0 ? 
                                    props.previousOrder.order.suggestedCarrier.name : 
                                    "Geen drageradvies"
                                }
                            </Text>
                        </Box>
                        <Box
                            sx={{
                                mt:2,
                            }}
                        >
                            
                            <Box
                                sx={{
                                }}
                            >
                                <Text color={tokens("white").txt["primary"]}>{t("shipper")}:</Text>
                                <Box 
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:'space-between',
                                    }}
                                >
                                    <Text color={getShipperColorSettingsByType(props.previousOrder.shipper.type).base} bold>{props.previousOrder.shipper.name}</Text>
                                </Box>
                            </Box>
                            
                        </Box>
                        {props.selectedShipper?.type !== "bol" && (
                            <Box
                                sx={{
                                    mt:2,
                                }}
                            >
                                <Box >
                                    <Text color={tokens("white").txt["primary"]}>{t("shipperprofile")}:</Text><br/>
                                    <Box
                                        sx={{
                                            display:"flex",
                                            justifyContent:'space-between',
                                            alignItems:'center',
                                        }}
                                    >
                                        <Text color={getShipperColorSettingsByType(props.previousOrder.shipper.type).base} bold>{props.previousOrder.shipper.name}</Text>
                                       
                                    </Box>
                                </Box>
                            
                            </Box>
                        )}
                     
                    </Box>

                </Box>
            </Box>
        </Box>

    );
}

export default PreviousOrderEchecked;