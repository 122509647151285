import { Box, ButtonBase, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../theme";
import Text from "../../../../../components/theme/text/Text";
import { format } from "date-fns";
import SHQuickAction from "../../../../../components/theme/Modal/SHQuickAction";
import { getShipperColorSettingsByType } from "../../../../../components/Streams/StreamShipperSettings";

import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useMemo } from "react";

const ShippedOrders = (props) => {
    const { t, ready,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 

    const ShipperLogoMap = {
        postnl: PostNLLogo,
        dhl: DhlLogo,
        dpd: DpdLogo,
        bol: BolLogo,
    };

    const renderShipperLogo = (shipperType) => {
        // Get the logo component based on shipper type
        const LogoComponent = ShipperLogoMap[shipperType];

        // Render the logo component if it exists, or null otherwise
        return LogoComponent ? <LogoComponent className={shipperType} /> : null;

    };

    const filteredAndReversedOrders = useMemo(() => {
        // First, filter based on workspaceId if filterBySelectedWorkSpace is true
        let orders = props.filterBySelectedWorkSpace
            ? props.shippedOrders.filter(order => {
                return order.workspaceId === props.workspaceId;
            })
            : props.shippedOrders;

        orders = [...orders].reverse(); // Ensure the array is reversed without mutating the original

        return orders;
    }, [props.shippedOrders, props.workspaceId, props.filterBySelectedWorkSpace]); // Dependencies: re-run if these change

    return (
        <>
            {filteredAndReversedOrders.map((order, index) => {
                // if(row.amountEchecked >= row.amount) return null;
                return (
                    <Box
                        key={"order-list"+index}
                        sx={{
                            pb:3,
                            ml:3.5,
                            position:'relative',
                            "&:before" : {
                                zIndex:2,
                                content:'""',
                                display:'block',
                                width:6,
                                height:6,
                                background:colors.grey[400],
                                position:'absolute',
                                top:10,
                                left:-24,
                                borderRadius:1,
                            },
                            "&:after" : {
                                zIndex:1,
                                content:'""',
                                display:'block',
                                width:2,
                                height:1,
                                background:colors.grey[100],
                                position:'absolute',
                                top:10,
                                left:-22,
                                borderRadius:1,
                            }
                        }}
                    >
                        <Text>{format(new Date(order.date_sent),'HH:mm:ss')}</Text>
                        {props.currentOrder ===null ?
                            <SHQuickAction
                                maxWidth={"unset"}
                                anchorOrigin={{
                                    vertical:'center',
                                    horizontal:"right"
                                }}
                                transformOrigin={{
                                    vertical:'center',
                                    horizontal:"left"
                                }}
                                component={
                                    <Box
                                        sx={{
                                            display:'flex',
                                            py:0.5,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                flexShrink:0,
                                            }}
                                        >
                                            {order.shipments.map((shipment,index) => (
                                                <Box
                                                    sx={{
                                                        mt:index===0?0:1.5,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display:"flex",
                                                            alignItems:"center",
                                                            flexDirection:"column",
                                                            px:2,
                                                            py:1.5,
                                                            pb:0.5,
                                                            // gap:2,
                                                            // height:32,
                                                            borderRadius:2,
                                                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                                                        }}
                                                    >
                                                        <Text bold>{shipment.trackingNumber}</Text>
                                                        <Box
                                                            sx={{
                                                                display:"flex",
                                                            }}
                                                        >
                                                            <Text light bold>{format(new Date(shipment.shipmentDate),'HH:mm:ss')}</Text>&nbsp;
                                                            <Text light italic>
                                                                {/* {new Date(props.date).toLocaleDateString(i18n.language)} */}
                                                                ({format(new Date(shipment.shipmentDate),'dd MMMM')})
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                        <Box
                                            sx={{
                                                px:2,
                                                flexShrink:0,
                                            }}
                                        >
                                            
                                            <Box
                                                sx={{
                                                    mb:1,
                                                    height:32,
                                                    display:"flex",
                                                    alignItems:'center',
                                                    px:2,
                                                    pl:0,
                                                    gap:1.5,
                                                    borderRadius:4,
                                                    background:getShipperColorSettingsByType(order.shipper.type).background,
                                                    color: getShipperColorSettingsByType(order.shipper.type).base,
                                                    fontWeight:"bold",
                                                    border: `1px solid ${getShipperColorSettingsByType(order.shipper.type).base}`,
                                                    "& .postnl" : {
                                                        width:24,
                                                        height:24,
                                                    },
                                    
                                                    "& .dpd" : {
                                                        width:24,
                                                        height:24,
                                                    },
                                                    "& .dhl" : {
                                                        width:24,
                                                        height:24,
                                                    },
                                                    "& .bol" : {
                                                        width:32,
                                                        // width:24,
                                                        height:16,
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height:32,
                                                        width:32,
                                                        borderRadius:4,
                                                        display:"flex",
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                        background:getShipperColorSettingsByType(order.shipper.type).background,
                                                        // "& svg" : {
                                                        //     fill:"white",
                                                        // },
                                                        // "& svg *" : {
                                                        //     fill:"white",
                                                        // }
                                                    }}

                                                >
                                                    {renderShipperLogo(order.shipper.type)}
                                                </Box>
                                                {order.shipper.name}
                                            </Box>
                                            {order.shipperProfile.name}
                                        </Box>
                                    </Box>
                                }
                            >
                                {({anchorEl,onMouseEnter,onMouseLeave,onTouchStart}) => (
                                    <ButtonBase
                                        aria-owns={anchorEl ? 'simple-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={onMouseEnter}
                                        onMouseLeave={onMouseLeave}
                                        onTouchStart={onTouchStart} 
                                        onClick={() => {
                                            props.setShipmentHighlightColor("blue");
                                            props.setSelectedOrder(order)
                                        }}
                                        key={index}
                                        sx={{
                                            display:"flex",
                                            flexDirection:"column",
                                            alignItems:"flex-start",
                                            mt:0.5,
                                            width:1,
                                            boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                            border:`1px solid ${colors.grey[100]}`,
                                            p:2,
                                            borderRadius:4,
                                            justifyContent:"space-between",
                                            background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width:1,
                                                display:"flex",
                                                justifyContent:"space-between"
                                            }}
                                        >
                                            <Text bold>{order.deliveryName}</Text>

                                            <Box
                                                sx={{
                                                    height:32,
                                                    display:"flex",
                                                    alignItems:'center',
                                                    justifyContent:"center",
                                                    px:2,
                                                    pl:0.5,
                                                    gap:1.5,
                                                    borderRadius:4,
                                                    background:getShipperColorSettingsByType(order.shipper.type).background,
                                                    color: getShipperColorSettingsByType(order.shipper.type).base,
                                                    fontWeight:"bold",
                                                    border: `1px solid ${getShipperColorSettingsByType(order.shipper.type).base}`,
                                                    "& .postnl" : {
                                                        width:24,
                                                        height:24,
                                                    },
                                    
                                                    "& .dpd" : {
                                                        width:24,
                                                        height:24,
                                                    },
                                                    "& .dhl" : {
                                                        width:24,
                                                        height:24,
                                                    },
                                                    "& .bol" : {
                                                        width:32,
                                                        // width:24,
                                                        height:16,
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height:24,
                                                        width:24,
                                                        borderRadius:4,
                                                        display:"flex",
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                        background:getShipperColorSettingsByType(order.shipper.type).background,
                                                    }}

                                                >
                                                    {renderShipperLogo(order.shipper.type)}
                                                </Box>
                                                {order.shipper.name}
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                mt:1,
                                                display:"flex",
                                                flexDirection:"column"
                                            }}
                                        >
                                            <Text>{order.shippingAddress.addressLine1}</Text>
                                            <Text>{order.shippingAddress.postalCode}, {order.shippingAddress.city}</Text>
                                            <Text>{order.shippingAddress.state}, {order.shippingAddress.country}</Text>
                                        </Box>
                                        {order.products.map((product,index) => (
                                            <Box
                                                sx={{
                                                    display:'flex',
                                                    justifyContent:"flex-start",
                                                    alignItems:"center",
                                                    width:1,
                                                    px:1,
                                                    pl:0.5,
                                                    py:0.5,
                                                    gap:1,
                                                    borderRadius:4,
                                                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100] ,
                                                    mt:index===0?3:1,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height:24,
                                                        minWidth:24,
                                                        px:1,
                                                        borderRadius:4,
                                                        background:theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400] ,
                                                        lineHeight:"24px",
                                                        flexShrink:0
                                                    }}
                                                >
                                                    <Text color={theme.palette.mode === "dark" ? null : colors.txt["white"]}>{product.amount}x</Text>
                                                </Box> 
                                                <Text bold>{product.product.description}</Text>
                                            </Box>
                                        ))}

                                    </ButtonBase>
                                )}
                            </SHQuickAction>
                        : 
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                alignItems:"flex-start",
                                mt:0.5,
                                width:1,
                                boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                border:`1px solid ${colors.grey[100]}`,
                                p:2,
                                borderRadius:4,
                                justifyContent:"space-between",
                                background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]
                            }}
                        >
                            <Box
                                sx={{
                                    width:1,
                                    display:"flex",
                                    justifyContent:"space-between"
                                }}
                            >
                                <Text bold>{order.deliveryName}</Text>
                                <ShLabel
                                    clickable
                                    // palette={order.shipments.length > 0 ? "pastel" : "pastel"}
                                    variant={order.shipments.length > 0 ? "lightblue" : order.shipments[0].synced ? "purple" : "orange"}
                                    fitted
                                    borderless
                                    size={32}
                                >
                                    <b>{order.shipments.length}</b> {t("shipments")}
                                </ShLabel>
                            </Box>
                            
                            <Box
                                sx={{
                                    mt:1,
                                    display:"flex",
                                    flexDirection:"column"
                                }}
                            >
                                <Text>{order.deliveryStreet} {order.deliveryHousenumber}</Text>
                                <Text>{order.deliveryPostalCode}, {order.deliveryCity}</Text>
                            </Box>

                        </Box>
                        }
                    </Box>
                );
            })}
        </>
    );
}

export default ShippedOrders;