import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const STOCK_BASE = "/orders";
const buildUrl = (endpoint) => STOCK_BASE + endpoint;

const ordersApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrders: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Orders' }],
          //  onQueryStarted: muiDataGridStarted.bind(null, 'getOrders', 'Orders'),
        }),
        getOrder: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'OrdersSingle' }],
        }),
        createOrder: builder.mutation({
            query: orderInfo => ({
                url: buildUrl(`/create`),
                method: 'POST',
                body: orderInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Orders'}, { type: 'OrderLines' }],
        }),
        duplicateOrder: builder.mutation({
            query: orderInfo => ({
                url: buildUrl(`/${orderInfo.id}/duplicate`),
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrdersSingle' }, {type: 'Orders'}, { type: 'OrderLines' }, { type: `LogsOrder`, id: arg.id }],
        }),
        updateOrder: builder.mutation({
            query: orderInfo => ({
                url: buildUrl(`/${orderInfo.id}`),
                method: 'PUT',
                body: orderInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrdersSingle' }, {type: 'Orders'}, { type: 'OrderLines' }, { type: `LogsOrder`, id: arg.id }],
        }),
        getOrderPickingList: builder.query({
            query: body => ({
                url: buildUrl(`/pickinglines`),
                method: 'POST',
                body: body,
                meta: { customContentType: true }, 
            }),
            invalidatesTags: [`OrderPickingList`],
        }),
        updateOrderPickingLine: builder.mutation({
            query: pickingLineInfo => ({
                url: buildUrl(`/${pickingLineInfo.orderId}/pickinglines/${pickingLineInfo.id}`),
                method: 'PUT',
                body: pickingLineInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrderPickingLines' }],
        }),
        getOrderPickingListPDF: builder.mutation({
            query: body => ({
                url: buildUrl(`/pickinglines?type=pdf`),
                method: 'POST',
                body: body,
                responseHandler: async (response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    // const blob = await response.blob();
                    const blob = new Blob([await response.blob()], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    return {data: url};
                },
            }),
        }),
        replaceShipment: builder.mutation({
            query: (body) => ({
                url: buildUrl(`/shipment/${body.id}/resend`),
                method: 'POST',
                body: body
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrdersSingle' }, { type: `LogsOrder`, id: arg.id }],
            //providesTags: (result, error, body) => [{ type: `${body.order.id}Single` }]
        }),
        cancelShipment: builder.mutation({
            query: id => ({
                url: buildUrl(`/shipment/${id}/cancel`),
                method: 'PUT',
            }),
            //providesTags: (result, error, body) => [{ type: `${body.order.id}Single` }]
        }),
        getOrderPickingLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/pickinglines`),
                method: 'POST',
                body: args.body,
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'OrderPickingLines' }],
        }),
        getOrderStatusCounts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/orderstatuscounts?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrdersStatusCounts' }], 
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrdersStatusCounts', 'OrdersStatusCounts')
        }),
        getDashboardOrderStatusCounts: builder.query({
            query: () => ({
                url: buildUrl(`/orderstatuscounts/dashboard`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'OrdersStatusCountsDashboard' }], 
            //onQueryStarted: muiDataGridStarted.bind(null, 'getDashboardOrdersStatusCounts', 'DashboardOrdersStatusCounts')
        }),
        getOrderCompareCountsDashboard: builder.query({
            query: () => ({
                url: buildUrl(`/ordercomparecountsdashboard`),
                method: 'GET',
            }),
            providesTags: () => [{ type: 'getOrderCompareCountsDashboard' }], 
            //onQueryStarted: muiDataGridStarted.bind(null, 'getDashboardOrdersStatusCounts', 'DashboardOrdersStatusCounts')
        }),
        getOrderLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/orderlines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrderLines' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrderLines', 'OrderLines')
        }),
        getReplenishLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/replenishlines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'ReplenishLines' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getReplenishLines', 'ReplenishLines')
        }),
        getOrderColli: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/colli?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrderColli' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrderColli', 'OrderColli')
        }),
        getOrderMutations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/mutations?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrderMutations' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrderMutations', 'OrderMutations')
        }),
        reserverOrderLine: builder.mutation({
            query: orderLineInfo => ({
                url: buildUrl(`/${orderLineInfo.orderId}/reserve`),
                method: 'POST',
                body: orderLineInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrdersSingle'}, { type: 'OrderLines' }, { type: 'Orders' }],
        }),
        splitOrder: builder.mutation({
            query: info => ({
                url: buildUrl(`/${info.id}/split`),
                method: 'POST',
                body: {products:info.products},
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrderPickingLines' }, {type: 'OrderLines'}, {type: 'Orders'}],
        }),
        getOrderShipments: builder.query({
            query: id => ({
                url: buildUrl(`/${id}/shipments`),
                method: 'GET'
            }),
            providesTags: () => [{ type: 'OrderShipments' }],
        }),
        getShipmentLabel: builder.query({
            query: id => ({
                url: buildUrl(`/shipmentlabel/${id}`),
                method: 'GET'
            }),
            providesTags: () => [{ type: 'ShipmentLabel' }],
        }),
        createShipmentLabel: builder.mutation({
            query: orderInfo => ({
                url: buildUrl(`/shipment/${orderInfo.id}/new`),
                method: 'POST',
                body: orderInfo
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrdersSingle' }, {type: 'Orders'}, { type: 'OrderLines' }, { type: `LogsOrder`, id: arg.id }],
        }),
        getShipmentLabelMutation: builder.mutation({
            query: id => ({
                url: buildUrl(`/shipmentlabel/${id}`),
                method: 'GET'
            }),
            providesTags: () => [{ type: 'ShipmentLabel' }],
        }),
        getDashboardOrderTotals: builder.query({
            query: (body) => ({
                url: buildUrl(`/dashboard/totals`),
                method: 'POST',
                body
            }),
        }),
        getRelationFullDashboard: builder.query({
            query: (body) => ({
                url: buildUrl(`/dashboard/relation/full`),
                method: 'POST',
                body
            }),
        }),
        getOrderTotals: builder.query({
            query: type => ({
                url: buildUrl(`/totals/${type}`),
                method: 'GET',
            }),
        }),
    })
});

// console.log(apiSlice);
export const { 
    useReplaceShipmentMutation,
    useGetRelationFullDashboardQuery,
    useGetDashboardOrderTotalsQuery,
    useGetOrdersQuery,
    useGetOrderQuery,
    useCreateOrderMutation,
    useDuplicateOrderMutation,
    useUpdateOrderMutation,
    useGetOrderStatusCountsQuery,
    useGetDashboardOrderStatusCountsQuery,
    useGetOrderCompareCountsDashboardQuery,
    useGetOrderLinesQuery,
    useGetOrderColliQuery,
    useGetOrderMutationsQuery,
    useGetOrderPickingListQuery,
    useGetOrderPickingListPDFMutation,
    useReserverOrderLineMutation,
    useGetOrderPickingLinesQuery,
    useUpdateOrderPickingLineMutation,
    useSplitOrderMutation,
    useGetOrderShipmentsQuery,
    useGetShipmentLabelQuery,
    useCreateShipmentLabelMutation,
    useGetReplenishLinesQuery,
    useGetShipmentLabelMutationMutation,
    useCancelShipmentMutation,
    useGetOrderTotalsQuery,
} = ordersApiSlice;
