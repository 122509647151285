import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import OrdersCard from "../../../../components/global/cards/orders/OrdersCard";
import ShLabel from "../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../components/theme/text/Text";
import { useGetOrdersQuery } from "../../../../newapi/order/ordersSlice";
import { tokens } from "../../../../theme";

import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import ShCopyButton from '../../../../components/theme/ShCopy';
import SidepanelLoader from "../../../../components/theme/sidepanel2/SidepanelLoader";
import { getOrderStateData } from "../../../../utils/labelColorText";
import { ORDER_FILTER_KEYS } from "../../../../components/global/DataGrid/Filter";
import { general_states } from "../../../../utils/staticEnums";
import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import { selectUserPermission } from "../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../utils/permissions';
import MpAddOrder from "../../../../components/global/ModalsProgression/MpAddOrder/MpAddOrder";

const SpOrderDetails = SidepanelLoader(() => import("../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return props.hasCreatePermission ?
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_order')}
            </Shbutton>
        </ButtonGroup> : <></>
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const OutboundShippedView = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    
    const [config, setConfig] = useState({
        [ORDER_FILTER_KEYS.STATE.backend_key]:[general_states.SHIPPED],
        "relationId": useSelector((state) => state.settings.environment.selectedId)
    });

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_ORDER_MANAGE])
    );
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };
    
    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>,
        });

        return () => {
            
        }
    },[]);

    const [orderId, setOrderId] = useState();
    const orderIdRef = useRef(orderId);
    
    const getOrderDetails = (params) => {
        if (!params) return;
    
        const neworderId = parseInt(params.row.id);
        // Only update state if the orderId has actually changed
        if (orderIdRef.current !== neworderId) {
            
            if(!spLoading) {
                setSpLoading(true);
            }
            setOrderId(neworderId);
            orderIdRef.current = neworderId; // Update the ref to the new value
        }
        setIsExtended(true);
    };


    const columns = [
        {field: "webshopreference", headerName: t("webshop"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box sx={{
                        minWidth:"100%",
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        whiteSpace:"nowrap",
                        wordBreak:"keep-all",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text noBreak>{params.row.webshopreference}</Text>
                        <ShCopyButton 
                            styles={{button:{
                                background:theme.palette.mode === "dark" ? colors.grey[600] :  colors.grey[200],
                                color: colors.txt["primary"],
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[500] :colors.grey[300],

                                }
                            }}} 
                            sx={{position:"absolute",right:0,zIndex:3000}} 
                            className={"copyel"} 
                            closeAfter={1500} 
                            value={params.row.webshopreference}
                        ></ShCopyButton>
                    </Box>
                );
            }
        },
        {field: "webshopcredentials", headerName: t("channel_name"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (<Text>{params.row.webshopcredentials?.description}</Text>)
            }
        },
        {field: "deliveryName", headerName: t("delivery_name"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.deliveryName;
            }
        },
        {
            field: "date_planned", 
            headerName: t("date_planned_shipment"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let planned = new Date(params.row.date_planned);
                let sent = params.row.date_sent ? new Date(params.row.date_sent) : null;
              
                return (
                    <ShLabel size={32} fitted variant="blue" palette="normal">
                        {!sent && planned.toLocaleDateString(i18n.language)}
                        {sent && sent.toLocaleDateString(i18n.language)}
                    </ShLabel>
                );
            }
        },
        {field: "orderlinesCount", headerName: t("orderlines_count"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.amountOfLines
        }},
        {field: "shipper", headerName: t("shipper_name"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.shipper?.name
        }},
        {
            field: "state", 
            headerName: t("state"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                const { labelcolor, labeltext } = getOrderStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrdersCard skeleton={params.row.skeleton ?? false} {...params.row}  />
                )
            }
        }
    ];
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrdersQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t('priority') }
                gridOptions={gridOptions} 
                 
                sortModel={{field: columns[0].field,type:"DESC"}}
                columns={columns}
                onRowClick={getOrderDetails}>
            </ShDataGrid2>

            {spLoading && 
                <SpOrderDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    orderId={orderId}
                    relation={true}
                    canReserveOrderLines={true}
                />
            }
            <MpAddOrder onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}


export default OutboundShippedView;