// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../../../../theme';
import { useGetProductsSelectQuery } from '../../../../../newapi/warehouse/productSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useDispatch } from 'react-redux';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();
    
    const [newOrderlines, setNewOrderlines] = useState([{
        sku: "",
        amount: 0,
        index: 1
    }]);

    const { data: products } = useGetProductsSelectQuery({webshopcredentialsId: data.webshopCredentialsId},
        {
            skip: !data.webshopCredentialsId
        }
    );

    const [productOptions, setProductOptions] = useState([]);

    useEffect(() => {
        if (products) {
            const mappedProducts = products.map(product => ({
                id: product.sku,
                name: product.combined
            }));

            setProductOptions(mappedProducts);
        }
    }, [products]);
    
    useEffect(() => {
        // Set the newInboundLines state to the data.inboundLines state
        if (data.orderlines && data.orderlines.length > 0) {
            const linesCopy = data.orderlines.map((line, index) => ({
                ...line,
                index: index
            }));
            
            linesCopy.push({
                sku: "",
                amount: 0,
                index: linesCopy[linesCopy.length - 1].index
            })

            setNewOrderlines(linesCopy);
        }
    },[]);

    const handleChange = (index, key, value) => {
        const orderlinesCopy = [...newOrderlines];
        orderlinesCopy[index][key] = value;

        if (orderlinesCopy[orderlinesCopy.length - 1].sku.length > 0) {
            orderlinesCopy.push({
                sku: "",
                amount: 0,
                index: orderlinesCopy.length
            });
        }

        setNewOrderlines(orderlinesCopy);
    };
    
    useEffect(() => {
        const mappedOrderlines = newOrderlines
        .filter(x => (x.sku.length > 0 && x.amount > 0))
        .map(x => ({
            sku: x.sku,
            amount: x.amount
        }));

        handleInput("orderlines", mappedOrderlines);
    },[newOrderlines]);
      
    const handleDeleleteLineRow = (index) => {
        const orderlinesCopy = [...newOrderlines];
        orderlinesCopy.splice(index, 1);
        setNewOrderlines(orderlinesCopy);
    }

    const handleProductSelect = (key, val) => {
        const lineIndex = key.split(".")[0];
        const lineKey = key.split(".")[1];
        handleChange(lineIndex, lineKey, val);
    }

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <Box
            sx={{
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                borderTopLeftRadius:16,
                borderTopRightRadius:16,
                display:"flex",
                flexDirection:"column",
                gap:1,
            }}
        >
            {newOrderlines.map((orderline, index) => (
                <Box flex={1} key={"new-orderline-"+index}
                    sx={{
                        display:"flex",
                        gap:4,
                        justifyContent:"flex-start",
                    }}
                >
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:80,
                            }}
                        >
                            <Text light>{t("sku")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <ShValidatedInputDropdown
                                key={"new-orderline-product-"+index}
                                displayName={"name"} 
                                changeField={"id"}
                                valueKey={"id"}
                                closeOnSelection
                                searchOptions={{
                                    enabled: true,
                                    placeholder: t("search_product_placeholder")
                                }}
                                styles={{
                                    minWidth:300
                                }}

                                disallowDeselection
                                name={`${index}.sku`}
                                selected={productOptions?.find(item => item.id === orderline.sku)?.id ?? -1}
                                options={productOptions ? productOptions : []}
                                noSelection={t("choose_option")} 
                                onChange={handleProductSelect}
                                error={props.errors.sku}
                            />
                        </Box>
                    </Box>
                    <Box 
                        sx={{
                            width:280,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:64,
                            }}
                        >
                            <Text light>{t("amount")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                width:144,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <NumberInput btnSize={"24"}
                                value={orderline.amount}
                                change={(e) => handleChange(index, 'amount', e)}/>
                        </Box>

                        { index > 0 ?
                        <Box sx={{width:40,display:"flex",justifyContent:"flex-end"}}>
                        <IconButton type="button" color="red" onClick={() => handleDeleleteLineRow(index)} sx={{p:1}}>
                            <DeleteIcon/>
                        </IconButton>
                        </Box> 
                        : <Box width={40}/>
                        }
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

const Orderlines = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
        tabId="tabOrderlines"
        subtitle={t("orderlines")} 
        step={3}
        maxSteps={3}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Orderlines;

